import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  setLayoutHeader,
  useCreateQuizMutation,
  useGetMeasureStepQuery,
  useMarkMeasureStepMutation,
  useMarkMeasureSubStepMutation,
} from 'store';

import {
  Article,
  DietCategory,
  Exercise,
  ExerciseProgram,
  InternalReference,
  MeasureStepReferenceType,
  QuizItem,
} from 'models';
import { Category, ContentWidth, Gap } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import * as routes from 'router/routes';
import { getImage } from 'utils/asset';

import HealthCard from 'components/cards/TestCard';
import RichText from 'components/fragments/RichText';
import Icon, { IconType } from 'components/UI/Icon';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import Button from 'components/UI/Button';
import Image from 'components/UI/Image';

import { CategoryHeader, ButtonGrid, NavButton, NavButtonContainer, NavText } from './styles';
import { useActiveLanguage } from 'hooks/useActiveLanguage';
import { confetti } from 'tsparticles-confetti';
import { toast } from 'react-hot-toast';
import ExcerciseProgramProgress from 'components/fragments/ExcerciseProgramProgress';
import DietPlanDay from 'components/fragments/DietPlanDay';
import { CardList } from '../styles';
import ButtonWithIcon from 'components/UI/ButtonWithIcon';
import { formatReadableDateTimeInclYear } from 'utils/date';
import { useCountdown } from 'hooks/useCountDown';

const MeasureProgramStep: FC = () => {
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const navigate = useNavigate();

  const location = useLocation();
  const intl = useIntl();
  const { slug, stepId, referenceId } = useParams();
  const quizComplete = location.state?.from;

  // Hooks
  const language = useActiveLanguage();
  const [markMeasureStep, markResult] = useMarkMeasureStepMutation();
  const [markMeasureSubStep] = useMarkMeasureSubStepMutation();
  const [createQuiz] = useCreateQuizMutation();
  const { data, isLoading, isFetching } = useGetMeasureStepQuery({
    slug,
    stepId,
    language: language?.languageCode,
  });
  const { unlockDate } = useCountdown(data?.minutesUntilCompletion);

  // States
  const [referenceData, setReferenceData] = useState<InternalReference | null>();
  const [referenceIndex, setReferenceIndex] = useState<number>(-1);

  useEffect(() => {
    if (referenceId && data?.references) {
      const index = data.references.findIndex((ref) => ref._id === referenceId);
      const reference = index !== -1 ? data.references[index] : null;
      setReferenceData(reference);
      setReferenceIndex(index);
    }
  }, [data?.references, referenceId]);

  // Navigate to next reference
  const handleNextReference = () => {
    if (data?.references) {
      if (referenceId) {
        markMeasureSubStep({
          measureSlug: slug as string,
          measureStepId: stepId as string,
          referenceId,
          language: language.languageCode,
          completed: true,
        });
      }
      if (referenceIndex !== -1 && referenceIndex < data.references.length - 1) {
        const nextReference = data.references[referenceIndex + 1];
        navigate(`${routes.MEASURES}/${slug}/step/${stepId}/${nextReference._id}`);
        window.scrollTo(0, 0);
      } else {
        navigate(
          referenceData ? `${routes.MEASURES}/${slug}/substep/${stepId}`
            :
            `${routes.MEASURES}/${slug}`,)
        window.scrollTo(0, 0);
      }
    }
  };

  // Navigate to previous reference
  const handlePreviousReference = () => {
    if (referenceIndex > 0 && data?.references) {
      const isReferenceStepsAfterIndexCompleted = data.references.slice(referenceIndex + 1).some(({ completed }) => completed);
      if (referenceId && !isReferenceStepsAfterIndexCompleted) {
        markMeasureSubStep({
          measureSlug: slug as string,
          measureStepId: stepId as string,
          referenceId,
          language: language.languageCode,
          completed: false,
        });
      }
      const previousReference = data.references[referenceIndex - 1];
      navigate(`${routes.MEASURES}/${slug}/step/${stepId}/${previousReference._id}`);
      window.scrollTo(0, 0);
    }
  };

  // Set header
  useEffect(() => {
    if (slug) {
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage({
            id: 'backButton',
            defaultMessage: 'Back',
            description: 'Back button text',
          }),
          inverted: true,
          icon: IconType.Back,
          link:
            referenceData ? `${routes.MEASURES}/${slug}/substep/${stepId}`
              :
              `${routes.MEASURES}/${slug}`,
        })
      );
    }
  }, [dispatch, intl, referenceData, slug, stepId]);

  // Actions
  const onStartClick = useCallback(
    (slug: string) => async () => {
      await createQuiz({ language: language?.languageCode, slug: slug });
      navigate(`${routes.QUIZ}/${slug}?redirect=${location.pathname}`, { state: { from: location.pathname } })
    },
    [createQuiz, language?.languageCode, navigate, location.pathname]);

  const onActiveClick = useCallback(
    () => navigate(routes.MEASURES),
    [navigate]
  );

  // Mark as done
  const onMarkDone = useCallback(
    (completed: boolean) => async () => {
      const result = markMeasureStep({
        measureSlug: slug as string,
        measureStepId: stepId as string,
        language: language.languageCode,
        completed,
      });
      if (completed) {
        const { steps } = await result.unwrap();
        const healthPlanCompleted =
          steps != null && steps.every(({ completed }) => completed);
        if (healthPlanCompleted) {
          if (slug === 'welcome-to-wellr') {
            navigate(routes.TESTS);
          }
          else {
            navigate(routes.MEASURES, {
              replace: true,
            });
          }
          window.scrollTo(0, 0);
          confetti({
            spread: 360,
            ticks: 50,
            gravity: 0,
            decay: 0.94,
            startVelocity: 30,
            colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
            particleCount: 80,
            scalar: 1.2,
            shapes: ['star'],
          });
          toast.success(
            intl.formatMessage({
              id: 'healthPlanCompleted',
              defaultMessage: 'Well done! You have completed a health plan!',
              description: 'Toast message for health plan completed',
            })
          );
        } else {
          navigate(`${routes.MEASURES}/${slug}`, {
            state: { scrollToMeasures: true },
          });
        }
      }
    },
    [markMeasureStep, slug, stepId, language.languageCode, navigate, intl]
  );

  // Mark button
  const renderMarkButton = useCallback(
    (completed: boolean) => {
      if (isFetching || markResult.isLoading) {
        return <Loader color="blue" />;
      }
      if (completed) {
        return (
          <Button
            background="white"
            color="pink"
            border="pink"
            onClick={onMarkDone(false)}
          >
            <FormattedMessage
              id="pageMeasureProgramStepUnmarkDone"
              defaultMessage="Unmark as done"
              description="Unmark as done button for exercise program step"
            />
          </Button>
        );
      }
      if (unlockDate) {
        return (
          <ButtonWithIcon
            icon={IconType.Clock}
            background="white"
            color="black"
            border="black"
            disabled={true}
          >
            <FormattedMessage
              id="measureStepLockedUntil"
              defaultMessage="Locked until"
            />
            {" "}{unlockDate ? formatReadableDateTimeInclYear(unlockDate) : ''}
          </ButtonWithIcon>
        );
      }

      return (
        <Button
          background="blue"
          color="white"
          border="blue"
          onClick={onMarkDone(true)}
        >
          <FormattedMessage
            id="pageMeasureProgramStepMarkDone"
            defaultMessage="Mark as done"
            description="Mark as done button for exercise program step"
          />
        </Button>
      );
    },
    [isFetching, markResult.isLoading, onMarkDone, unlockDate]
  );

  // Right nav
  const navigationButtons = useMemo(() => {
    if (isLoading) {
      return null;
    }
    return (
      <NavButtonContainer>
        {referenceIndex > 0 &&
          <NavButton left onClick={handlePreviousReference}>
            <Icon type={IconType.Arrow} color="black" />
          </NavButton>
        }
        <NavButton right onClick={handleNextReference}>
          {referenceIndex !== -1 && data?.references && referenceIndex < data.references.length - 1 ? (
            <Icon type={IconType.Arrow} color="black" />
          ) : (
            <NavText>
              <FormattedMessage
                id="finishButton"
              />
            </NavText>
          )}
        </NavButton>

      </NavButtonContainer>
    );
  }, [handleNextReference, handlePreviousReference, isLoading, referenceIndex]);

  // Content
  const content = useMemo(() => {
    if (!data) {
      return null;
    }

    const { data: refData, type: refType, slug: refSlug } = referenceData ? referenceData : data.reference;
    switch (refType) {
      case MeasureStepReferenceType.ExerciseProgram:
        const program = refData as ExerciseProgram;
        return (
          <ExcerciseProgramProgress
            slug={refSlug}
            exerciseDescriptionPairs={program.exerciseDescriptionPairs}
            exerciseProgramSteps={program.exerciseProgramSteps}
          />
        );
      case MeasureStepReferenceType.Exercise:
        const exercise = refData as Exercise;
        return (
          <Fragment>
            <Image image={getImage(exercise.image)} />
            <Heading tag={Tag.H2}>{getText(exercise.title)}</Heading>
            {exercise.content && <RichText>{exercise.content}</RichText>}
          </Fragment>
        );
      case MeasureStepReferenceType.Article:
        const article = refData as Article;
        return (
          <Fragment>
            <Image image={getImage(article.image)} />
            <Category>{article.category.title !== null && getText(article.category.title)}</Category>
            <Heading tag={Tag.H2}>{getText(article.title)}</Heading>
            {article.content && <RichText>{article.content}</RichText>}
          </Fragment>
        );
      case MeasureStepReferenceType.Diet:
        const diets = refData as DietCategory;
        const diet = diets.diet[0];
        if (!diet) {
          return null;
        }

        const { title, image, content, dietPlanWeeks } = diet;
        return (
          <Fragment>
            <Image image={getImage(image)} />
            <Heading tag={Tag.H2}>{getText(title)}</Heading>
            {content && <RichText>{content}</RichText>}
            <CardList>
              {dietPlanWeeks && dietPlanWeeks.map(({ title, breakfastLunchDinner, key }, i) => {
                if (!breakfastLunchDinner) {
                  return null;
                }
                const id = `${data.reference.slug}-diet-plan-day-${i + 1}`;
                return (
                  <DietPlanDay
                    id={id}
                    weekKey={key}
                    key={key}
                    days={breakfastLunchDinner}
                    title={title}
                    refSlug={data.reference.slug}
                  />
                );
              })}
            </CardList>
          </Fragment>
        );
      case MeasureStepReferenceType.Quiz:
        const quiz = refData as QuizItem;
        return (
          <HealthCard
            key={quiz.quizSlug}
            category={quiz.quizDefinition.healthCategory}
            text={quiz.quizDefinition.summary}
            wellrPoints={quiz.quizDefinition.wellrPoints}
            results={quizComplete ? quiz.quizResults : []}
            onClick={onStartClick(quiz.quizSlug)}
            onActiveClick={onActiveClick}
            buttonText={intl.formatMessage({
              id: 'takeTestButton',
              defaultMessage: 'Take test',
              description: 'Take test button text',
            })}
            buttonActiveText={intl.formatMessage({
              id: 'implementMeasuresButton',
              defaultMessage: 'To the health plans',
              description: 'Implement measures button text',
            })}
          />
        );
      default:
        return null;
    }
  }, [data, intl, getText, onActiveClick, onStartClick, quizComplete, referenceData]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return (
      <EmptyState iconType={IconType.Health} padding>
        <FormattedMessage
          id="pageMeasureProgramStepEmptyState"
          defaultMessage="Measure step not found"
          description="Empty state for measure program step"
        />
      </EmptyState>
    );
  }

  const { title, stepNumber, completed } = data;

  return (
    <Fragment>
      <HeroSmall>
        <CategoryHeader>
          <Category>
            <FormattedMessage
              id="pageMeasureProgramStepByline"
              defaultMessage="Step {stepNumber}"
              description="Page byline for measure program step"
              values={{
                stepNumber,
              }}
            />
          </Category>
          <Heading>{getText(title)}</Heading>
        </CategoryHeader>
      </HeroSmall>
      <ContentWidth isSurface>
        {content}
        <Gap />
        {!referenceData &&
          <ButtonGrid>{renderMarkButton(completed)}</ButtonGrid>
        }
        {referenceData && navigationButtons}
      </ContentWidth>
    </Fragment>
  );
};

export default MeasureProgramStep;
