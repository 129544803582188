import { useState, useEffect } from 'react';

interface CountdownReturn {
  formattedTime: string;
  isActive: boolean;
  secondsLeft: number;
  unlockDate: Date | null;
}

export const useCountdown = (minutes?: number): CountdownReturn => {
  const [formattedTime, setFormattedTime] = useState<string>('00:00');
  const [secondsLeft, setSecondsLeft] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [unlockDate, setUnlockDate] = useState<Date | null>(null);

  useEffect(() => {
    if (!minutes) return;

    const totalSeconds = minutes * 60;
    setSecondsLeft(totalSeconds);
    setIsActive(true);

    // Set initial unlock date
    const newUnlockDate = new Date();
    newUnlockDate.setMinutes(newUnlockDate.getMinutes() + Math.floor(totalSeconds / 60));
    newUnlockDate.setSeconds(newUnlockDate.getSeconds() + (totalSeconds % 60));
    setUnlockDate(newUnlockDate);

    const formatTime = (seconds: number) => {
      const mins = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    setFormattedTime(formatTime(totalSeconds));

    const intervalId = setInterval(() => {
      setSecondsLeft(prev => {
        if (prev <= 0) {
          clearInterval(intervalId);
          setIsActive(false);
          setUnlockDate(null);
          return 0;
        }
        const newSeconds = prev - 1;
        setFormattedTime(formatTime(newSeconds));
        return newSeconds;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [minutes]);

  return { formattedTime, isActive, secondsLeft, unlockDate };
};