
import EmptyState from 'components/UI/EmptyState';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import { IconType } from 'components/UI/Icon';
import { useAppDispatch } from 'hooks/redux';
import { useActiveLanguage } from 'hooks/useActiveLanguage';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { FC, useEffect, useCallback, useMemo, Fragment, useState, } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { setLayoutHeader, useGetMeasureStepQuery, useMarkMeasureStepMutation } from 'store';
import { Category, ContentWidth, Gap } from 'styles';
import { CardList } from '../styles';
import MeasureStepCard from 'components/cards/MeasureStepCard';
import { ButtonGrid, CategoryHeader } from './styles';
import * as routes from 'router/routes';
import { Article } from 'models';
import Loader from 'components/UI/Loader';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Button';
import toast from 'react-hot-toast';
import { confetti } from 'tsparticles-confetti';
import ButtonWithIcon from 'components/UI/ButtonWithIcon';
import { formatReadableDateTimeInclYear } from 'utils/date';
import { useCountdown } from 'hooks/useCountDown';

const MeasureProgramSubStep: FC = () => {

  // Hooks
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const navigate = useNavigate();
  const intl = useIntl();
  const { slug, stepId } = useParams();
  const language = useActiveLanguage();
  const [markMeasureStep, markResult] = useMarkMeasureStepMutation();
  const { data, isLoading, isFetching } = useGetMeasureStepQuery({
    slug,
    stepId,
    language: language?.languageCode,
  });
  const { unlockDate } = useCountdown(data?.minutesUntilCompletion);

  // States
  const [isAllSubStepsCompleted, setIsAllSubStepsCompleted] = useState<boolean>(false);

  // Set header
  useEffect(() => {
    if (slug) {
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage({
            id: 'backButton',
            defaultMessage: 'Back',
            description: 'Back button text',
          }),
          inverted: true,
          icon: IconType.Back,
          link: `${routes.MEASURES}/${slug}`,
        })
      );
    }
  }, [dispatch, intl, slug, stepId]);

  useEffect(() => {
    if (data?.references) {
      setIsAllSubStepsCompleted(data.references.every(({ completed }) => completed));
    }
  }, [data]);


  // Actions
  const onStepClick = useCallback(
    (slug: string, stepId: string, referenceId: string) => async () => {
      navigate(`${routes.MEASURES}/${slug}/step/${stepId}/${referenceId}`);
    },
    [navigate]
  );

  // Mark as done
  const onMarkDone = useCallback(
    (completed: boolean) => async () => {
      const result = markMeasureStep({
        measureSlug: slug as string,
        measureStepId: stepId as string,
        language: language.languageCode,
        completed,
      });
      if (completed) {
        const { steps } = await result.unwrap();
        const healthPlanCompleted =
          steps != null && steps.every(({ completed }) => completed);
        if (healthPlanCompleted) {
          if (slug === 'welcome-to-wellr') {
            navigate(routes.TESTS);
          }
          else {
            navigate(routes.MEASURES, {
              replace: true,
            });
          }
          window.scrollTo(0, 0);
          confetti({
            spread: 360,
            ticks: 50,
            gravity: 0,
            decay: 0.94,
            startVelocity: 30,
            colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
            particleCount: 80,
            scalar: 1.2,
            shapes: ['star'],
          });
          toast.success(
            intl.formatMessage({
              id: 'healthPlanCompleted',
              defaultMessage: 'Well done! You have completed a health plan!',
              description: 'Toast message for health plan completed',
            })
          );
        } else {
          navigate(`${routes.MEASURES}/${slug}`, {
            state: { scrollToMeasures: true },
          });
        }
      }
    },
    [markMeasureStep, slug, stepId, language.languageCode, navigate, intl]
  );

  // Mark button
  const renderMarkButton = useCallback(
    (completed: boolean) => {
      if (isFetching || markResult.isLoading) {
        return <Loader color="blue" />;
      }
      if (completed) {
        return (
          <Button
            background="white"
            color="pink"
            border="pink"
            onClick={onMarkDone(false)}
          >
            <FormattedMessage
              id="pageMeasureProgramStepUnmarkDone"
              defaultMessage="Unmark as done"
              description="Unmark as done button for exercise program step"
            />
          </Button>
        );
      }
      if (unlockDate) {
        return (
          <ButtonWithIcon
            icon={IconType.Clock}
            background="white"
            color="black"
            border="black"
            disabled={true}
          >
            <FormattedMessage
              id="measureStepLockedUntil"
              defaultMessage="Locked until"
            />
            {" "}{unlockDate ? formatReadableDateTimeInclYear(unlockDate) : ''}
          </ButtonWithIcon>
        );
      }
      return (
        <Button
          background="blue"
          color="white"
          border="blue"
          onClick={onMarkDone(true)}
          disabled={!isAllSubStepsCompleted}
        >
          <FormattedMessage
            id="pageMeasureProgramStepMarkDone"
            defaultMessage="Mark as done"
            description="Mark as done button for exercise program step"
          />
        </Button>
      );
    },
    [isFetching, markResult.isLoading, onMarkDone, isAllSubStepsCompleted, unlockDate]
  );

  // Content
  const content = useMemo(() => {
    // Mark as done
    if (!data) {
      return (
        <EmptyState iconType={IconType.Health} padding>
          <FormattedMessage
            id="pageMeasureProgramEmptyState"
            defaultMessage="Measure program not found"
            description="Empty state for measure program"
          />
        </EmptyState>
      );
    }

    return (
      <CardList>
        {data?.references.map((step, i) => {
          const refData = step.data as Article;
          const isLocked = i > 0 && !data.references[i - 1].completed;
          return (
            <MeasureStepCard
              key={step._id}
              title={refData.title}
              isCompleted={step.completed}
              stepNumber={i + 1}
              onClick={onStepClick(
                slug!,
                stepId!,
                step?._id!
              )}
              locked={isLocked}
            />
          );
        })}
      </CardList>
    )
  }, [data, onStepClick, slug, stepId]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return (
      <EmptyState iconType={IconType.Health} padding>
        <FormattedMessage
          id="pageMeasureProgramStepEmptyState"
          defaultMessage="Measure step not found"
          description="Empty state for measure program step"
        />
      </EmptyState>
    );
  }

  const { title, stepNumber, completed } = data;

  return (
    <Fragment>
      <HeroSmall>
        <CategoryHeader>
          <Category>
            <FormattedMessage
              id="pageMeasureProgramStepByline"
              defaultMessage="Step {stepNumber}"
              description="Page byline for measure program step"
              values={{
                stepNumber,
              }}
            />
          </Category>
          <Heading>{getText(title)}</Heading>
        </CategoryHeader>
      </HeroSmall>
      <ContentWidth isSurface>
        {content}
        <Gap />
        <ButtonGrid>{renderMarkButton(completed)}</ButtonGrid>
      </ContentWidth>
    </Fragment>
  );
};

export default MeasureProgramSubStep;
