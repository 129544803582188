import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  localizationSelector,
  useCreateQuizMutation,
  useGetAvailableQuizesQuery,
  useGetQuizResultsQuery,
  useGetUserQuery,
} from 'store';
import { useQuery } from 'hooks/useQuery';
import { useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getCategoryIcon } from 'utils/asset';
import * as routes from 'router/routes';

import Heading from 'components/UI/Heading';
import IconButton from 'components/UI/IconButton';
import BackgroundCover from 'components/UI/BackgroundCover';
import EmptyState from 'components/UI/EmptyState';
import Icon, { IconType } from 'components/UI/Icon';
import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';

import {
  QuizHeader,
  QuizBody,
  HeaderLeftCol,
  HeaderRightCol,
  Content,
  HealthCategory,
  ResultContainer,
  ResultHeader,
  ResultText,
  ResultActions,
} from './styles';
import { QuizAssessment, QuizComplete } from 'models';

const QuizResults: FC<{ pulseQuizResult?: QuizAssessment }> = ({
  pulseQuizResult,
}) => {
  // Hooks
  const navigate = useNavigate();
  const getText = useLocalizedText();
  const location = useLocation();
  const params = useParams();
  const [redirect] = useQuery('redirect');
  const { language } = useAppSelector(localizationSelector);

  const slug = params?.slug;
  const isPulseQuiz = pulseQuizResult != null;
  const quizComplete = location.state?.ContestQuizComplete as QuizComplete;
  const measureQuizComplete = location.state?.MeasuresQuizComplete as QuizComplete;
  const isContestReview = location.pathname.includes('evaluation-of-the-activity-challenge');
  const isContestHealthQuiz = quizComplete?.quizType.toString() === 'healthQuiz';
  const { refetch } = useGetUserQuery(undefined, {});

  useEffect(() => {
    refetch();
  }, []);

  // Hooks
  const { data, isLoading } = useGetQuizResultsQuery(
    {
      slug,
      language: language?.languageCode,
      noOfResults: 1,
    },
    { skip: isPulseQuiz }
  );
  const availableQuizes = useGetAvailableQuizesQuery({
    language: language?.languageCode,
    inclDeletedResults: true,
  });
  const [createQiuz] = useCreateQuizMutation();

  // Next quiz
  const nextQuiz = useMemo(() => {
    if (isPulseQuiz || !availableQuizes.data) {
      return null;
    }
    const found = availableQuizes.data.find(
      (item) => item.quizSlug !== slug && !item.quizResults.length
    );
    return found || null;
  }, [availableQuizes.data, isPulseQuiz, slug]);

  // Close quiz
  const onClose = useCallback(() => {
    const target = quizComplete?.from || measureQuizComplete?.from || redirect || routes.TESTS;
    const state = measureQuizComplete ? { state: { from: location.pathname } } : undefined;

    navigate(target, state);
  }, [measureQuizComplete, navigate, quizComplete, redirect, location.pathname]);

  // Next quiz
  const onNextQuiz = useCallback(async () => {
    if (nextQuiz) {
      await createQiuz({
        language: language?.languageCode,
        slug: nextQuiz.quizSlug,
      });
      navigate(`${routes.QUIZ}/${nextQuiz.quizSlug}`);
    }
  }, [navigate, createQiuz, nextQuiz, language]);

  // Health category
  const healthCategory = useMemo(() => {
    if (isPulseQuiz || !data) {
      return null;
    }
    const { healthCategory } = data.quizDefinition;
    if (!healthCategory) {
      return null;
    }
    const { title, icon } = healthCategory;
    const img = getCategoryIcon(icon, true);
    return (
      <HealthCategory>
        {img && <img src={img.src} alt={img.alt} color="white" />}
        {getText(title)}
      </HealthCategory>
    );
  }, [getText, data, isPulseQuiz]);

  // Content
  const content = useMemo(() => {
    // Loading
    if (isLoading) {
      return <Loader padding />;
    }
    let assessmentResult: QuizAssessment;

    if (isPulseQuiz) {
      assessmentResult = pulseQuizResult;
    } else {
      // No data
      if (!data) {
        return (
          <EmptyState iconType={IconType.Health} padding inverted>
            <FormattedMessage
              id="pageQuizResultEmptyState"
              defaultMessage="Quiz result not found"
              description="Empty state for quiz result"
            />
          </EmptyState>
        );
      }
      const [result] = data.quizResults;
      assessmentResult = result.assessment;
    }

    const { resultTitle, resultText } = assessmentResult;
    return (
      <ResultContainer
        transition={{ duration: 0.2 }}
        initial={{ y: 32, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -32, opacity: 0 }}
      >
        <ResultHeader>
          {isContestHealthQuiz || !isContestReview ? (
            <FormattedMessage
              id="pageQuizResultByline"
              defaultMessage="Your result"
              description="Byline for quiz result"
            />
          ) : (
            <FormattedMessage
              id="pageQuizThanksForYourInputReviewByline"
              defaultMessage="Thanks for your input!"
              description="Byline for quiz thanks for your input review"
            />
          )}
        </ResultHeader>
        <Heading>{!isContestReview && getText(resultTitle)}</Heading>
        <ResultText>{!isContestReview && getText(resultText)}</ResultText>
        <ResultActions>
          {!quizComplete && !isContestReview && nextQuiz ? (
            <Button color="blue" background="white" onClick={onNextQuiz}>
              <FormattedMessage
                id="nextQuizButton"
                defaultMessage="Take next quiz"
                description="Next quiz button text"
              />
            </Button>
          ) : null}
          <Button background="transparent" onClick={onClose}>
            <FormattedMessage
              id="finishButton"
              defaultMessage="Finish"
              description="Finish button text"
            />
          </Button>
        </ResultActions>
      </ResultContainer>
    );
  }, [
    isLoading,
    isPulseQuiz,
    isContestHealthQuiz,
    isContestReview,
    getText,
    quizComplete,
    nextQuiz,
    onNextQuiz,
    onClose,
    pulseQuizResult,
    data,
  ]);

  return (
    <BackgroundCover padding>
      <QuizHeader>
        <HeaderLeftCol>{healthCategory}</HeaderLeftCol>
        <HeaderRightCol>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} color="white" />
          </IconButton>
        </HeaderRightCol>
      </QuizHeader>
      <QuizBody>
        <Content>{content}</Content>
      </QuizBody>
    </BackgroundCover>
  );
};

export default QuizResults;
