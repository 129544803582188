import { baseApi } from '../baseApi';
import { UserAchievementResponseType } from 'models/userachievement/userachievement';

const userAchievementApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserAchievements: builder.query<UserAchievementResponseType[], void>({
      query: () => ({
        url: '/user-achievement/me',
        method: 'GET',
      }),
      providesTags: ['UserAchievement'],
    }),
  }),
});

export const { useGetUserAchievementsQuery } = userAchievementApi;
