import { ActivityToplist, TotalActivityToplist } from 'models';
import {
  getEndpointURL,
  WellrEndpointDefinition,
  WellrEndpointPath,
  
} from 'utils/api';

const getActivityStatePath: WellrEndpointPath = '/api/activity/me/company/total-steps';
const getActivityStateMethod = 'get';
type GetActivityState = WellrEndpointDefinition<
  typeof getActivityStatePath,
  typeof getActivityStateMethod
>;

const getTotalPointsStatePath: WellrEndpointPath = '/api/activity/me/company/total-points';
const getTotalPointsStateMethod = 'get';
type GetTotalPointsState = WellrEndpointDefinition<
  typeof getTotalPointsStatePath,
  typeof getActivityStateMethod
>;

const getTotalActivityToplistStatePath: WellrEndpointPath = '/api/activity/me/company/total-useractivites' ;

const getTotalActivityToplistMethod = 'get';
type GetTotalActivityToplistState = WellrEndpointDefinition<
  typeof getTotalActivityToplistStatePath,
  typeof getTotalActivityToplistMethod
>;

const getTotalActivitylistStatePath: WellrEndpointPath = '/api/activity/me/company/total-allCompaniesUserActivities' ;

const getTotalActivitylistMethod = 'get';
type GetTotalActivitylistState = WellrEndpointDefinition<
  typeof getTotalActivitylistStatePath,
  typeof getTotalActivitylistMethod
>;

export type GetActivityStateType = {
  result: ActivityToplist;
  queryArgs: Required<GetActivityState['request']['params']['query']>;
};

export type GetTotalPointsStateType = {
  result: ActivityToplist;
  queryArgs: Required<GetTotalPointsState['request']['params']['query']>;
};

export type GetTotalActivityToplistStateType = {
  result: TotalActivityToplist;
  queryArgs: Required<GetTotalActivityToplistState['request']['params']['query']>;
};

export type GetTotalActivitylistStateType = {
  result: TotalActivityToplist;
  queryArgs: Required<GetTotalActivitylistState['request']['params']['query']>;
};

export const endpoints = {
  getActivityState: {
    path: getEndpointURL(getActivityStatePath),
    method: getActivityStateMethod,
  },
  getTotalPointsState: {
    path: getEndpointURL(getTotalPointsStatePath),
    method: getTotalPointsStateMethod,
  },
  getTotalActivityToplistState: {
    path: getEndpointURL(getTotalActivityToplistStatePath),
    method: getTotalActivityToplistMethod,
  },
  getTotalActivitylistState: {
    path: getEndpointURL(getTotalActivitylistStatePath),
    method: getTotalActivitylistMethod,
  },
};
