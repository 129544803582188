import React, { useCallback, useState } from 'react';
import { useGetMembershipSettingsQuery, useUpdateMembershipSettingsMutation } from 'store';
import { IconType } from 'components/UI/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import { InformationMessage } from '../OverviewTopList/styles';
import { MembershipSettings } from 'models';
import { ActionContainer } from 'styles';
import ContentModal from 'components/modals/ContentModal';
import Button from 'components/UI/Button';
import EmptyState from 'components/UI/EmptyState';
import Loader from 'components/UI/Loader';
import ToggleCard from 'components/UI/Cards/ToggleCard';


const TopListSettings: React.FC = () => {
  const settings = useGetMembershipSettingsQuery();
  const [updateSettings] = useUpdateMembershipSettingsMutation();
  const intl = useIntl();

  const onToggle = useCallback(
    (key: keyof MembershipSettings) => (active: boolean) => {
      const { data } = settings;
      if (data) {
        updateSettings({ ...data, [key]: active });
      }
    },
    [updateSettings, settings]
  );

  const [topListInfoModalOpen, setTopListInfoModalOpen] =
    useState<boolean>(false);

  const onOpenTopListInfoModal = useCallback(
    () => setTopListInfoModalOpen(true),
    []
  );
  const onCloseTopListInfoModal = useCallback(
    () => setTopListInfoModalOpen(false),
    []
  );


  if (settings.isLoading) {
    return <Loader color="blue" padding />;
  }

  if (settings.data?.showInToplistOverview === true) {
    return null;
  }

  return (
    <>
      <EmptyState iconType={IconType.Settings} padding>
        <InformationMessage>
          <FormattedMessage
            id="overviewToplistActivateMessage"
            defaultMessage="Activate toplist"
          />
          <Button
            color="white"
            border="borderLight"
            background="pink"
            size="small"
            onClick={onOpenTopListInfoModal}
          >
            <FormattedMessage id="pageSettingsTitle" defaultMessage="Settings" />
          </Button>
        </InformationMessage>
      </EmptyState>

      <ContentModal
        isOpen={topListInfoModalOpen}
        onClose={onCloseTopListInfoModal}
        title={intl.formatMessage({ id: 'pageCompetitionTopListTitle' })}
      >
        <FormattedMessage
          id="overviewTopListInfoPolicy"
          defaultMessage={`
            Welcome to the Toplist! We're glad you want to join.{NewLine}
            To participate, you need to give your approval. By participating in the Toplist, you agree that we will display your name, score, and progress on our leaderboard, so everyone can see your achievement and you can see theirs.
            {NewLine}{NewLine}
            But don't worry, if you ever change your mind, you can easily adjust your settings by visiting your profile. We look forward to seeing you on the leaderboard and sharing the joy of achievements together!`}
          description="Information message for top list"
          values={{ NewLine: <br /> }}
          tagName={'p'}
        />
        <ActionContainer>
          <ToggleCard
            title={intl.formatMessage({
              id: 'settingsShowInToplistOverviewTitle',
              defaultMessage: 'Show me in top list in overview',
              description: 'Settings title for show me in top list',
            })}
            isActive={settings?.data?.showInToplistOverview ?? false}
            onToggle={() => {
              onToggle('showInToplistOverview')(!settings.data?.showInToplistOverview);
              onCloseTopListInfoModal();
            }}
          />
        </ActionContainer>
      </ContentModal>
    </>
  );
};

export default TopListSettings;