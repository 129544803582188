
import {
  getEndpointURL,
  WellrEndpointDefinition,
  WellrEndpointPath,
} from 'utils/api';

const getAchievementStatePath: WellrEndpointPath = '/api/achievement/{slug}';
const getAchievementStateMethod = 'get';
type GetAchievementState = WellrEndpointDefinition<
  typeof getAchievementStatePath,
  typeof getAchievementStateMethod
>;

export type GetAchievementStateType = {
  result: GetAchievementState['responseBody'];
  queryArgs: Required<GetAchievementState['request']['params']['path']>;
};

export const endpoints = {
  getAchievementEntity: {
    path: (slug: string) =>
      getEndpointURL(getAchievementStatePath, {
        slug,
      }),
    method: getAchievementStateMethod
  },
};
