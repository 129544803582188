import { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';

import {
  useAddContestUserMutation,
  useGetInvitableMembershipsQuery,
} from 'store';

import Loader from 'components/UI/Loader/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';
import { useForm } from 'react-hook-form';
import SelectField from 'components/UI/SelectField';
import { SelectOption } from 'models';
import { ActionContainer } from 'styles';
import Button from 'components/UI/Button';
import MemberSelectFromStructure from 'components/fragments/MemberSelectFromStructure';
import TextField from 'components/UI/TextField';
import { SelectedMembershipsContext, useMembershipState } from 'context/useSelectedMembershipContext';

type AddCompetitionUserForm = {
  userId?: string;
  teamId?: string;
  forceAccept?: boolean;
  filterUsers?: string;
};

type Props = {
  contestId: string;
  teams?: SelectOption[];
  isOpen: boolean;
  onClose: () => void;
  alreadyAddedMembersEmails?: string[];
};

const AddChallengeParticipantModal: FC<Props> = ({
  contestId,
  teams,
  isOpen,
  onClose,
  alreadyAddedMembersEmails = []
}) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<AddCompetitionUserForm>();

  // Hooks
  const { selectedMemberships, toggleSelectMembership } = useMembershipState();
  const { data: invitableMemberships, isLoading: invitableMembershipsIsLoading } = useGetInvitableMembershipsQuery(undefined, { skip: !isOpen });
  const [addContestUser, addContestUserResult] = useAddContestUserMutation();

  const addableUserOptions: SelectOption[] = useMemo(() => {
    if (!invitableMemberships) {
      return [];
    }
    return invitableMemberships
      .filter((user) => !alreadyAddedMembersEmails.includes(user.email))
      .map((user) => ({
        name: user.email ?? '',
        value: user.membershipId,
      }));
  }, [invitableMemberships, alreadyAddedMembersEmails]);

  const [selectedUserId, filterUsers] =
    watch([
      'userId',
      'filterUsers'
    ]);

  useEffect(() => {
    if (addContestUserResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'AddContestUserModalSuccess',
          defaultMessage: 'User has been added to the contest',
          description: 'User add contest success message',
        })
      );
      onClose();
    }
  }, [addContestUserResult.isSuccess, intl, onClose, setValue]);

  // Content
  const content = useMemo(() => {
    if (
      !invitableMemberships ||
      addContestUserResult.isLoading ||
      invitableMembershipsIsLoading
    ) {
      return <Loader padding color="blue" />;
    }
    if (invitableMemberships?.length === 0) {
      return (
        <FormattedMessage
          id="addContestUserModalNoUsers"
          defaultMessage="No more users can be added to this contest"
          description="No addable users available message"
        />
      );
    }
    const buttonEnabled = selectedMemberships != null ;
    return (
      <form
        onSubmit={handleSubmit((form) =>
          addContestUser({
            contestId,
            membershipId: selectedMemberships,
            teamId: form.teamId === '' ? undefined : form.teamId,
          })
        )}
      >
        <>
          <TextField
            label={intl.formatMessage({
              id: 'inputFilterUsersLabel',
              defaultMessage: 'Search users',
              description: 'Label for filter users input',
            })}
            placeholder={intl.formatMessage({
              id: 'inputFilterUsersPlaceholder',
              defaultMessage: 'Search for user name or email',
              description: 'Placeholder for filter users input',
            })}
            register={register('filterUsers')}
            blurOnEnter
          />
          <SelectedMembershipsContext.Provider
            value={{
              selected: selectedMemberships,
              onToggle: toggleSelectMembership,
            }}
          >
            <MemberSelectFromStructure filter={filterUsers} />
          </SelectedMembershipsContext.Provider>
        </>

        <br />
        {teams && teams.length > 0 && (
          <SelectField
            label={intl.formatMessage({
              id: 'inputTeamLabel',
              defaultMessage: 'Team',
              description: 'Label for team input',
            })}
            register={register('teamId')}
            placeholder={intl.formatMessage({
              id: 'inputTeamPlaceholder',
              defaultMessage: 'No team',
              description: 'Placeholder for team input',
            })}
            options={teams}
            error={errors.teamId}
          />
        )}
        <ActionContainer>
          <Button type="submit" background="blue" disabled={!buttonEnabled}>
            <FormattedMessage
              id="addButton"
              defaultMessage="Add"
              description="Add button text"
            />
          </Button>
        </ActionContainer>
      </form>
    );
  }, [invitableMemberships, addContestUserResult.isLoading, invitableMembershipsIsLoading, selectedMemberships, handleSubmit, intl, register, toggleSelectMembership, filterUsers, teams, errors.teamId, addContestUser, contestId]);

  const onModalClose = () => {
    onClose();
    reset();
  };

  return (
    <SlideoutModal isOpen={isOpen} onClose={onModalClose}>
      <div>
        <Header>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="addContestUserModalTitle"
              defaultMessage="Add user to contest"
              description="Add contest user modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default AddChallengeParticipantModal;
