import styled from 'styled-components';

export const HorizontalBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;

  border-radius: 1.2em;
  margin-bottom: 0.5em;
  background-color: rgba(121, 126, 135, 0.1);
  cursor: pointer;
`;

export const BadgeImage = styled.div`
  flex-shrink: 0;

  img {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const BadgeInfo = styled.div`
  margin-left: 15px;

  .title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 0;
  }

  .description {
    font-size: 14px;
    color: #666;
    margin: 5px 0 0;
  }
`;
