import MilestonePopup from 'components/popups/MilestonePopup';
import Button from 'components/UI/Button';
import Heading, { Tag } from 'components/UI/Heading';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SmallGap } from 'styles';
import { ReadMoreContainer } from './styles';
import giftLottieAnimation from 'assets/animations/gift-animation.json';

interface MilestonePopupContentProps {
  handleCharmaGift: () => void;
  charmaGiftOpen: boolean;
  charmaGiftClose: () => void;
  isLoading: boolean;
}

const CharmaGiftPopup: React.FC<MilestonePopupContentProps> = ({
  handleCharmaGift,
  charmaGiftOpen,
  charmaGiftClose,
  isLoading,
}) => {
  const intl = useIntl();

  const [readMore, setReadMore] = useState<boolean>(false);

  return (
    <MilestonePopup
      onConfirm={handleCharmaGift}
      isOpen={charmaGiftOpen}
      onAbort={charmaGiftClose}
      lottieIcon={giftLottieAnimation}
      confettis={false}
      cancelText={intl.formatMessage({
        id: 'noThanksButton',
        defaultMessage: 'No thanks',
        description: 'Cancel button text',
      })}
    >
      <>
        {!readMore ? (
          <>
            <Heading tag={Tag.H3} style={{ color: 'black' }}>
              <FormattedMessage
                id="welcomeBackGiftHeading"
                defaultMessage="Välkommen tillbaka!"
                description="Heading text in the milestone popup"
              />
            </Heading>
            <FormattedMessage
              id="welcomeBackGiftBody"
              defaultMessage="Här är en gåva som tack för att du väljer att vara en del av Wellr."
              description="Body text in the milestone popup"
            />
            <SmallGap />
            <ReadMoreContainer>
              <FormattedMessage
                id="welcomeBackGiftBodyIsProvidedBy"
                defaultMessage="Gåvan är levererad av Charma."
                description="Body text in the milestone popup"
              />
              <br />
              <Button
                onClick={() => setReadMore(true)}
                background="transparent"
                color="grayText"
                size='small'
                disabled={isLoading}
              >
                <div style={{ textDecoration: 'underline', fontSize: '14px' }}>
                  <FormattedMessage
                    id="readMoreButton"
                    defaultMessage="Read more"
                    description="Read more button text"
                  />
                </div>
              </Button>
            </ReadMoreContainer>
          </>
        ) : (
          <>
            <Heading tag={Tag.H3} style={{ color: 'black' }}>
              <FormattedMessage
                id="welcomeBackGiftHeading"
                defaultMessage="Välkommen tillbaka!"
                description="Heading text in the milestone popup"
              />
            </Heading>
            <FormattedMessage
              id="welcomeBackGiftReadMoreBody"
              defaultMessage="Wellr har ett samarbete med Charma som leverantör av företagsgåvor.
               Charma är ett svenskt företag som har syfte att hjälpa företag med företagsgåvor och att visa uppskattning till både medarbetare som kunder.
                Vill du veta mer om hur charma behandlar dina personuppgifter kan du läsa mer om det här: "
              description="Body text in the milestone popup"
              values={{ NewLine: <br /> }}
            />
            <br />
            <a href="https://charma.io/p/privacy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
              <FormattedMessage
                id="charmaPrivacyLink"
                defaultMessage="Read Charma's privacy policy"
                description="Link text for Charma's privacy policy"
              />
              <SmallGap />
            </a>
          </>
        )}
      </>
    </MilestonePopup>
  );
};

export default CharmaGiftPopup;