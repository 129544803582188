import React from 'react';
import { ToggleButtonContainer, ToggleButton } from './styles';
import { useIntl } from 'react-intl';

interface Props {
  activePage: string;
  handlePageSwitch: (page: string) => void;
  isWellrSteps: boolean;
  setIsWellrSteps: (value: boolean) => void;
}

const LeadershipToggleButtons: React.FC<Props> = ({
  activePage,
  handlePageSwitch,
  isWellrSteps,
  setIsWellrSteps,
}) => {
  const intl = useIntl();
  return (
    <ToggleButtonContainer>
      <ToggleButton
        isActive={activePage === 'global'}
        onClick={() => handlePageSwitch('global')}
      >
        {intl.formatMessage({ id: 'allCompaniesButton' })}
      </ToggleButton>
      <ToggleButton
        isActive={activePage === 'local'}
        onClick={() => handlePageSwitch('local')}
      >
        {intl.formatMessage({ id: 'myCompanyButton' })}
      </ToggleButton>
      <ToggleButton
        isActive={isWellrSteps}
        onClick={() => setIsWellrSteps(!isWellrSteps)}
      >
        {intl.formatMessage({ id: 'stepsButton' })}
      </ToggleButton>
    </ToggleButtonContainer>
  );
};

export default LeadershipToggleButtons;
