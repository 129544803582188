import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Navigate, useOutletContext } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { setLayoutHeader, useGetAllContestTeamsQuery } from 'store';
import { ContentWidth } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import * as routes from 'router/routes';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { ContestContext } from 'models';

import { IconType } from 'components/UI/Icon';
import CompetitionStatus from 'components/fragments/CompetitionStatus';
import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';

const CompetitionAllTeams: FC = () => {
  const { contest } = useOutletContext<ContestContext>();
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();

  const { goals, me, id, title, totalAmount } = contest;
  const [isDerbyCompetition, setIsDerbyCompetition] = useState<boolean>(contest.goals[0].type === 'Derby');

  // Hooks
  const teams = useGetAllContestTeamsQuery(
    {
      contestId: id,
    },
    { skip: !me.team }
  );

  useEffect(() => {
    setIsDerbyCompetition(contest.goals[0].type === 'Derby');
  }, [contest.goals]);

  // Link
  const link = useMemo(() => `${routes.COMPETE}/competitions/${id}`, [id]);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: getText(title),
        icon: IconType.Back,
        link,
      })
    );
  }, [dispatch, getText, title, link]);

  // Redirect if no team
  if (!me.team) {
    return <Navigate to={link} />;
  }

  // Loading
  if (teams.isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!teams.data) {
    return (
      <EmptyState iconType={IconType.Competition} padding>
        <FormattedMessage
          id="pageCompetitionEmptyState"
          defaultMessage="Competition not found"
          description="Empty state for competition"
        />
      </EmptyState>
    );
  }

  return (
    <Fragment>
      <ContentWidth isSurface>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageCompetitionStatusAllTeamsTitle"
            defaultMessage="Status all teams"
            description="Title for status section on competition all teams page"
          />
        </Heading>
        <CompetitionStatus
          goals={goals}
          teams={teams.data}
          team={me.team}
          totalAmount={isDerbyCompetition ? contest.goals[0].amount : totalAmount}
          specialCompetition={contest.specialContest}
          derby={isDerbyCompetition }
        />
      </ContentWidth>
    </Fragment>
  );
};

export default CompetitionAllTeams;
