import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetAssetImage } from 'hooks/useGetAssetImage';
import coinBadge from 'assets/vectors/wellr-badge.svg';
import { ContestGoalUnits } from 'models/contest/contest';

import {
  Item,
  ItemText,
  ItemGrid,
  ItemProgress,
  ItemHeader,
  ItemHeaderCol,
  ImgContainer,
  Img,
  Badge,
  TotalStepsContainer,
  ItemTextContainer,
  UserEmail,
  UserInfoContainer,
  UserName,
  UserPosition,
} from './styles';
import { ThemeColor } from 'styles';
import Icon, { IconType } from 'components/UI/Icon';
import Bubble from 'components/UI/Bubble';
import DerbyProgressBar from 'components/UI/DerbyProgressBar.tsx';
import { CompletedGoal, ContestGoal } from 'models';

type Props = {
  alias: string;
  email: string | null;
  profilePictureAssetId: string | null;
  showResults?: boolean;
  totalUnits: number;
  progress: number | null;
  progressColor?: ThemeColor;
  toplistPosition?: number;
  myPosition?: number;
  totalText?: string;
  isLast: boolean;
  points?: boolean;
  goals: ContestGoal[];
  completedGoals?: CompletedGoal[];
  completedCheckpoints?: CompletedGoal[];
  unit?: ContestGoalUnits;
  isMe: boolean;
};


const TopListProgressDerbyItem: FC<Props> = ({
  alias,
  email,
  profilePictureAssetId,
  showResults = true,
  totalUnits,
  progress,
  progressColor = 'blue',
  totalText = null,
  isLast,
  points,
  toplistPosition,
  goals,
  completedGoals,
  completedCheckpoints,
  unit,
  isMe,
}) => {

  // Hooks
  const [imageSrc] = useGetAssetImage(profilePictureAssetId);
  const progressRounded =
    progress == null
      ? null
      : progress > 99
        ? Math.floor(progress)
        : Math.round(progress);

  const [progressColorState, setProgressColor] = useState<ThemeColor>(progressColor);

  useEffect(() => {
    setProgressColor(progressColor);
  }, []);

  const minutes = unit && unit === 'Minute';

  return (
    <Item
      isLast={isLast}
    >
      <ItemHeader>
        <ItemHeaderCol style={{ overflow: 'visible' }}>
          <ImgContainer style={{ position: 'relative', overflow: 'visible' }} >
            <div style={{ position: 'absolute', top: 5, right: 37 }}>
              <Bubble color={'white'} background={'orange'} toplist={true} />
            </div>
            {imageSrc ? (
              <Img src={imageSrc} alt={alias} />
            ) : (
              <Icon type={IconType.User} color="grayText" size={20} />
            )}
          </ImgContainer>
          {showResults && progressRounded != null && (
            <ItemGrid>
              <ItemProgress>
                <DerbyProgressBar
                  completedCheckpoints={completedCheckpoints}
                  completedGoals={completedGoals}
                  goals={goals}
                  progress={progressRounded}
                  color={progressColorState}
                  unit={unit} />
              </ItemProgress>
            </ItemGrid>
          )}
        </ItemHeaderCol>
        <ItemTextContainer>
          <ItemHeaderCol>
            <UserPosition>{toplistPosition}</UserPosition>
            <UserInfoContainer>
              <UserName isCurrentUser={isMe}>{alias}</UserName>
              {email && <UserEmail>{email}</UserEmail>}
            </UserInfoContainer>
          </ItemHeaderCol>
          {showResults && (
            <ItemText>
              {totalText ? (
                totalText
              ) : (
                <>
                  {points ? (
                    <TotalStepsContainer>
                      <FormattedMessage
                        id="activitiesPointsText"
                        defaultMessage="points"
                        description="Unit text for activities"
                        values={{ points: totalUnits }}
                      />
                      <Badge src={coinBadge} alt="points badge" />
                    </TotalStepsContainer>
                  ) : minutes ? (
                    <TotalStepsContainer>
                      {totalUnits}{' '}
                      <FormattedMessage
                        id="adminContestActivityToplistMinutes"
                      />
                      <Icon type={IconType.Clock} color="grayText" />
                    </TotalStepsContainer>
                  )
                    : (
                      <TotalStepsContainer>
                        {totalUnits}{' '}
                        <FormattedMessage
                          id="activitiesUnitText"
                          defaultMessage="Steps"
                          description="Unit text for activities"
                        />
                        <Icon type={IconType.Footprints} color="grayText" />
                      </TotalStepsContainer>
                    )}
                </>
              )}
            </ItemText>
          )}
        </ItemTextContainer>
      </ItemHeader>
    </Item>
  );
};

export default TopListProgressDerbyItem;
