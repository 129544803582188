import React, { FC, useEffect } from 'react';
import { BadgeDetailsContainer, Overlay, IconContainer, DiagonalOverlay, LabelContainer, LabelText, ButtonContainer } from './styles';
import { BadgeformatDate } from 'utils/date';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon, { IconType } from 'components/UI/Icon';
import Button from 'components/UI/Button';


type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  image: string;
  title: string;
  date: string;
};

const AchievementBadgePopup: FC<Props> = ({
  isOpen,
  image,
  title,
  date,
  onConfirm,
}) => {
  const intl = useIntl();

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const handleClosePopup = () => {
    onConfirm();
  };

  if (!isOpen) return null;

  return (
    <Overlay>
      <BadgeDetailsContainer>

        <div className="modal-content">
          <div>
            <div className="badge-image">
              <img src={image} alt={title} />
                <LabelContainer>
                  <DiagonalOverlay />
                  <LabelText>NEW</LabelText>
                </LabelContainer>
            </div>
            <div className="badge-details">
              <h6>
                <IconContainer>
                  <Icon type={IconType.Calendar} color="grayText" size={22} />
                </IconContainer>
                <span>{intl.formatMessage({ id: 'pageUnlockedDate' })} {BadgeformatDate(date)}</span>
              </h6>
              <h2>{intl.formatMessage({ id: 'newUnlockedAchievement' })}
                {' ' + title}
                <br />
              </h2>
              <p>
                <strong>
                  {intl.formatMessage({ id: 'badgeCongratulationsTitle' }) + ' '}
                </strong>
                {intl.formatMessage({ id: 'badgeUnlockedDescription' })}
              </p>
            </div>
          </div>

          <ButtonContainer>
            <Button
              size="large"
              color="white"
              background="blue"
              border="backgroundDark"
              rounded
              width='100%'
              onClick={() => handleClosePopup()}
            >
              <FormattedMessage
                id={'continueButton'}
              />
            </Button>
          </ButtonContainer>
        </div>
      </BadgeDetailsContainer>
    </Overlay>
  );
};

export default AchievementBadgePopup;