import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

type ActivityProps = {
  isLast: boolean;
};

export const Item = styled.div<ActivityProps>`
  display: flex;
  background: ${({ theme }) => theme.colors.backgroundLight};
  align-items: center;
  overflow: hidden;
  border-radius: 16px;
  min-height: 64px;
  gap: 8px;
  padding: 8px;

  justify-content: space-between;
  border-bottom: ${({ theme, isLast }) =>
    !isLast ? `1px solid ${theme.colors.borderLight}` : 'none'};

  & >* {
    overflow: hidden;
    text-overflow: ellipsis;
    }
  h4 {
    margin: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  & >* {
    overflow: hidden;
    text-overflow: ellipsis;
    }
`;
export const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;

`;
export const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 8px;
  justify-content: end;
  align-items: center;
  flex-direction: row;
`;

export const ItemText = styled.div`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  & >* {
    overflow: hidden;
    text-overflow: ellipsis;
    }
`;

export const SelectItem = styled.div`
  display: flex;
  padding: 8px;
  cursor: pointer;
`;