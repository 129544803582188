import styled, { keyframes } from 'styled-components';
import { breakpoints } from 'styles';

export const BadgeDetailsContainer = styled.div`
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-height: 750px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .modal-content {
    .badge-image {
      margin-bottom: 20px;

      img {
        aspect-ratio: 1/1;
        width: 250px;
        object-fit: contain;
        border-radius: 10px;
      }
    }

    .badge-details {
      height: 100%;
      color: ${({ theme }) => theme.colors.grayText};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 500px;

      h2 {
        margin-bottom: 10px;
        font-size: 1.5rem;
        color: #222;
        }

        h6 {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 245px;
        font-weight: 500;
        background-color: #e0e0e0;
        border-radius: 10px;
        padding: 0.1rem 1.2rem
      }

      p {
        font-size: 1rem;
        padding: 0 15px;
      }
    }

    .continue-btn {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1rem;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  @media (max-width: ${breakpoints.xs}px) {
    width: 90%;
    margin-left: -3px;

    .modal-content {
      .badge-image img {
        width: 150px;
        height: 150px;
      }
    }
  }
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 80px;
  width: 99%;
  margin: 0 auto;
  @media (max-width: ${breakpoints.xs}px) {
    width: 90%;
  }
`;

export const BackButton = styled.button`
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

export const IconContainer = styled.div`
  margin-top: 0.5em;
`;

export const BadgeDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
  height: 200px;
`;
export const ButtonContainer = styled.div`
  width: 100%;
  max-width: 340px;
`;

export const Img = styled.img<{ isLocked?: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: ${({ isLocked }) => (isLocked ? 'grayscale(100%)' : 'none')};
`;