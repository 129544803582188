import React from 'react';
import { BadgeImage, BadgeInfo, HorizontalBadgeContainer } from './styles';

interface HorizontalBadgeProps {
  image: string;
  title: string;
  description?: string;
  onClick?: () => void;
  isLocked: boolean; 
}

const HorizontalBadge: React.FC<HorizontalBadgeProps> = ({
  image,
  title,
  description,
  onClick,
  isLocked,
}) => {
  return (
    <HorizontalBadgeContainer
      onClick={onClick}
      style={{ cursor:'pointer' }}
    >
      <BadgeImage>
        <img
          src={image}
          alt={title}
          style={{
            filter: isLocked ? 'grayscale(100%)' : 'none', 
            opacity: isLocked ? 0.6 : 1, 
          }}
        />
      </BadgeImage>
      <BadgeInfo>
        <p className="title">{title}</p>
        <p className="description">{description}</p>
      </BadgeInfo>
    </HorizontalBadgeContainer>
  );
};

export default HorizontalBadge;


