import {
  Article,
  ContentBody,
  ContentText,
  DietCategory,
  Exercise,
  ExerciseProgram,
  HealthCategory,
} from 'models/content/content';
import { QuizItem } from 'models/quiz/quiz';

export type MeasureQuery = {
  slug?: string;
  language?: string;
  stepId?: string;
  completed?: boolean;
};

export enum MeasureStepReferenceType {
  ExerciseProgram = 'exerciseProgram',
  Exercise = 'exercise',
  Article = 'article',
  Quiz = 'quiz',
  Diet = 'diet',
}

export type MeasureStep = {
  id: string;
  title: ContentText;
  wellrPoints: number;
  completed: boolean;
  stepNumber: number;
  completeByTime: boolean;
  minutesUntilCompletion: number;
  multipleSteps: boolean;
  reference: InternalReference
  references: InternalReference[];
};

export type InternalReference = {
  _id: string;
  type: MeasureStepReferenceType;
  slug: string;
  completed: boolean;
  data: ExerciseProgram | Exercise | Article | QuizItem | DietCategory | null;
};

export type Measure = {
  id: string;
  slug: string;
  wellrPoints: number;
  healthCategory: HealthCategory;
  title: ContentText | null;
  content: ContentBody | null;
  steps: MeasureStep[];
};

export type MeasureItem = {
  completed: string;
  id: string;
  slug: string;
  wellrPoints: number;
  healthCategory: HealthCategory;
  title: ContentText | null;
};
