import React, { FC, Fragment, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContestGoal, ContestTeam } from 'models';
import Heading, { Tag } from 'components/UI/Heading';
import ProgressBar from 'components/UI/ProgressBar';

import {
  TeamList,
  TeamItem,
  TeamItemText,
  TeamItemGrid,
  TeamItemProgress,
  TeamItemHeader,
  TeamRank,
  TeamItemData,
  TeamItemHeaderName,
} from './styles';
import { formatReadableDateTime } from 'utils/date';
import Icon, { IconType } from 'components/UI/Icon';
import DerbyProgressBar from 'components/UI/DerbyProgressBar.tsx';

type Props = {
  goals: ContestGoal[];
  teams: ContestTeam[];
  team: ContestTeam | null;
  derby?: boolean;
};

const CompetitionTeams: FC<Props> = ({ goals, teams, team, derby }) => {
  // Render list
  const list = useMemo(() => {
    if (!team) {
      return teams;
    }
    if (teams.some((item) => item.id === team.id)) {
      return teams;
    }
    return [...teams, team];
  }, [teams, team]);

  return (
    <Fragment>
      {goals.map((goal) => (
        <TeamList key={goal.id}>
          {list.map((item, i) => {
            const cut = 100 * (item.total / goal.amount);
            const progress = cut > 99 ? Math.floor(cut) : Math.round(cut);
            const myTeam = team ? team.id === item.id : false;
            return (
              <TeamItem key={item.id}>
                <TeamRank isMine={myTeam} rank={item.position}>
                  {item.position || '-'}
                </TeamRank>
                <TeamItemData>
                  <TeamItemHeader>
                    <TeamItemHeaderName>
                      <Heading tag={Tag.H4}>
                        {!myTeam ? (
                          <>
                            {item.name}
                            {item.completed && (
                              <>
                                {' '}
                                {`- ${formatReadableDateTime(new Date(item.completed))}`}
                                <Icon type={IconType.CheckeredFlag} size={14} />
                              </>
                            )}
                          </>
                        ) : (
                          <FormattedMessage
                            id="competitionTeamsMyTeam"
                            defaultMessage="{name} (My team)"
                            description="My team text for competition teams"
                            values={{
                              name: item.name,
                            }}
                          />
                        )}
                      </Heading>
                    </TeamItemHeaderName>
                    <TeamItemText>
                      {item.total}{' '}
                      <FormattedMessage
                        id="activitiesUnitText"
                        defaultMessage="steps"
                        description="Unit text for activities"
                      />
                    </TeamItemText>
                  </TeamItemHeader>
                  <TeamItemGrid>
                    <TeamItemText>{`${progress}%`}</TeamItemText>
                    <TeamItemProgress>
                      {derby ? (
                        <DerbyProgressBar
                          goals={goals}
                          progress={progress}
                        />
                      ) : (
                        <ProgressBar progress={progress} color="blue" />
                      )}
                    </TeamItemProgress>
                  </TeamItemGrid>
                </TeamItemData>
              </TeamItem>
            );
          })}
        </TeamList>
      ))}
    </Fragment>
  );
};

export default CompetitionTeams;
