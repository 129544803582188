import Button from 'components/UI/Button';
import Heading, { Tag } from 'components/UI/Heading';
import { IconType } from 'components/UI/Icon';
import TextField from 'components/UI/TextField';
import { backendApi } from 'config';
import { usePageHeader } from 'hooks/usePageHeader';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as routes from 'router/routes';
import { useCreateCompanyFromTemplateMutation } from 'store';
import { ContentWidth, MediumGap } from 'styles';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from 'components/UI/Loader';
import ConfirmActionPopup from 'components/popups/ConfirmActionPopup';
import { ButtonContainer } from './styles';

const templateUrl = `${backendApi}/company/register-from-template`;

const AdminCompanyCreate: FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const [showConfirm, setShowConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const baseUrl = window.location.href.split(location.pathname)[0];

  usePageHeader({
    title: intl.formatMessage({
      id: 'pageAdminCompaniesTitle',
      defaultMessage: 'Companies',
      description: 'Page title for admin companies',
    }),
    inverted: false,
    icon: IconType.Back,
    link: routes.ADMIN_COMPANIES,
  });

  const [createCompany, createCompanyResult] = useCreateCompanyFromTemplateMutation();

  useEffect(() => {
    if (createCompanyResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'createCompanySuccess',
          defaultMessage: 'Company created successfully',
          description: 'Company create toast success message',
        })
      );
      navigate(routes.ADMIN_COMPANIES);
    } else if (createCompanyResult.isError) {
      toast.error(
        intl.formatMessage({
          id: 'createCompanyError',
          defaultMessage: 'Company creation failed',
          description: 'Company create toast error message',
        })
      );
    }
  }, [
    createCompanyResult.isSuccess,
    createCompanyResult.isError,
    intl,
    navigate,
  ]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<{ file: FileList }>();

  const onSubmit = handleSubmit(({ file }) => {
    createCompany({ file: file[0], updateExistingCompany: isUpdate });
  });

  return (
    <ContentWidth isSurface>
      <Heading tag={Tag.H2}>
        <FormattedMessage
          id="pageWellrAdminCompanyCreateTitle"
          defaultMessage="Create company"
          description="Heading1 for admin company create page"
        />
      </Heading>
      <FormattedMessage
        id="pageWellrAdminCompanyCreateDescription"
        defaultMessage="Create a company using the Excel company template file. Download the template {link} and upload the filled file below."
        description="Description for admin company create page"
        values={{
          link: (
            <a href={templateUrl} download>
              <FormattedMessage
                id="pageWellrAdminCompanyCreateDescriptionLink"
                defaultMessage="here"
                description="Link label for admin company create excel template download link"
              />
            </a>
          ),
        }}
      />

      <Heading tag={Tag.H2}>
        <FormattedMessage
          id="pageWellrAdminCompanyUpdateTitle"
          defaultMessage="Update company"
          description="Heading1 for admin company create page"
        />
      </Heading>
        <FormattedMessage
          id="pageWellrAdminCompanyUpdateDescription"
          defaultMessage="Create a company using the Excel company template file. Download the template {link} and upload the filled file below."
          description="Description for admin company create page"
        />
      <MediumGap />
      <form onSubmit={onSubmit}>
        <TextField
          label={intl.formatMessage({
            id: 'inputCompanyFileInputLabel',
            defaultMessage: 'Company excel definition',
            description: 'Label for start date input',
          })}
          type="file"
          error={errors.file}
          register={register('file', { required: true })}
        />
        <ButtonContainer>
          <Button
            onClick={() => {
              setIsUpdate(false);
              setShowConfirm(true);
            }}
            background="blue"
            disabled={createCompanyResult.isLoading}
          >
            <FormattedMessage
              id="saveButton"
              defaultMessage="Save"
              description="Save button text"
            />
          </Button>
          <Button
            onClick={() => {
              setIsUpdate(true);
              setShowConfirm(true);
            }}
            disabled={createCompanyResult.isLoading}
          >
            <FormattedMessage
              id="updateButton"
              defaultMessage="Update"
              description="Update button text"
            />
          </Button>
        </ButtonContainer>
        {createCompanyResult.isLoading && <Loader color="blue" padding />}
      </form>
      <ConfirmActionPopup
        isOpen={showConfirm}
        onConfirm={onSubmit}
        onAbort={() => setShowConfirm(false)}
      >
        <>
          <Heading tag={Tag.H4}>
            {isUpdate ? (
              <FormattedMessage
                id="updateCompanyConfirmHeading"
                defaultMessage="Update company"
                description="Heading text in the add company popup"
              />
            ) : (
              <FormattedMessage
                id="addCompanyConfirmHeading"
                defaultMessage="Add company"
                description="Heading text in the add company popup"
              />
            )}
          </Heading>
          {isUpdate ? (
            <FormattedMessage
              id="updateCompanyConfirmBody"
              defaultMessage="Are you sure you want to update this company?"
              description="Body text in the add company popup"
            />
          ) : (
            <FormattedMessage
              id="addCompanyConfirmBody"
              defaultMessage="Are you sure you want to add this company?"
              description="Body text in the add company popup"
            />
          )}
          <br />
          {baseUrl}
        </>
      </ConfirmActionPopup>
    </ContentWidth>
  );
};

export default AdminCompanyCreate;