import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { UserActivity } from 'models';

import StaticCard from 'components/UI/Cards/StaticCard';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';
import TopListProgressItem from 'components/fragments/TopListProgressItem';

import { TeamList } from './styles';
import { useGetMembershipSettingsQuery } from 'store';
import TopListSettings from '../TopListSettings';

type Props = {
  me: UserActivity;
  users?: UserActivity[];
  points?: boolean;
  total: number;
  page?: number;
};

const OverviewTopList: FC<Props> = ({
  me,
  users,
  total,
  points,
  page
}) => {


  //hooks
  const settings = useGetMembershipSettingsQuery();

  if (settings.data?.showInToplistOverview === false) {
    return (
      <TopListSettings
      />
    )
  }
  // Check if users exist
  else if (!users?.length) {
    return (
      <EmptyState iconType={IconType.Users} padding>
        <FormattedMessage
          id="competitionTopListEmptyState"
          defaultMessage="No users found"
          description="Empty state for competition top list"
        />
      </EmptyState>
    );
  }

  return (
    <StaticCard>
      <TeamList>
        {users.map((item, i) => {
          const user = users.find((user) => user.userId === item.userId);
          if (!user) {
            return null;
          }

          let progress: number | null;
          const cut = points ? user.points / total : user.steps / total;
          progress = !isNaN(cut) && isFinite(cut) ? cut * 100 : 0;

          return (
            <TopListProgressItem
              key={user.userId}
              email={user.email}
              alias={user.firstName + ' ' + user.lastName}
              profilePictureAssetId={user.profilePictureAssetId}
              totalSteps={points ? user.points : user.steps}
              progress={progress}
              toplistPosition={i + 1}
              isLast={users.length === i + 1}
              points={points}
              page={page}
            />
          );
        })}
      </TeamList>
    </StaticCard>
  );
};

export default OverviewTopList;
