import { motion } from 'framer-motion';
import styled from 'styled-components';
import { ThemeColor } from 'styles';

type Props = {
  isLast: boolean;
  color?: ThemeColor;
};

type ImgProps = {
  color?: ThemeColor;
};

function hexToRgbA(hex: string, alpha: number = 1) {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`;
  }
  throw new Error('Bad Hex');
}

export const Item = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background: ${({ theme, color }) =>
    color ? ` ${hexToRgbA(theme.colors[color], 0.5)}` : undefined};
      border-radius: ${({ color, isLast }) =>
    color === 'goldBright' ? '16px 16px 0 0' :
      isLast ? '0px 0px 16px 16px' : '0'};
    border-bottom: ${({ theme, isLast }) =>
    !isLast ? `1px solid ${theme.colors.borderLight}` : 'none'};
  h4 {
    margin: 0;
  }
`;

export const ItemHeader = styled.div`
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  & > * {
    overflow: hidden;
  }
`;

export const ItemHeaderCol = styled.div`
  display: flex;
  width: 100%;
  & > * {
    text-overflow: ellipsis;
  }
`;

export const ItemGrid = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const ItemText = styled.span`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  white-space: nowrap;
  text-align: bottom;

`;

export const UserAliasContainer = styled.span`
  color: ${({ theme }) => theme.colors.black};
  text-align: bottom;
  font-size: 16px;
  font-weight: 600;
  text-overflow: wrap;
  overflow: auto;
  max-height: 40px;
`;

export const ProgressText = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  left: 0;
  bottom: 0;
`;

export const ItemProgress = styled.div`
  width: 100%;
`;

export const ImgContainer = styled.div<ImgProps>`
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
  width: 40px;
  height: 40px;
  border-radius: 99px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.backgroundDark};
  border: 1px solid ${({ theme, color }) => color ? `${theme.colors[color]}` : theme.colors.borderLight};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex-shrink: 0;

  :hover {
  scale: 1.2;
  }
`;

export const Img = styled.img`
border-radius: 99px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Badge = styled.img`
  width: 24px;
  height: 24px;
`;

export const TotalStepsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
`;

export const Container = styled.div`
  height: 8px;
  background: ${({ theme }) => theme.colors.backgroundDark};
  border-radius: 4px;
  overflow: hidden;
`;

type BarProps = {
  color: ThemeColor | string;
};

export const Bar = styled(motion.div) <BarProps>`
  background: ${({ color, theme }) =>
    color.startsWith('#') ? color : theme.colors[color as ThemeColor]};
  height: 8px;
`;

export const ItemTextContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 4px;
  align-items: center;
  justify-content: space-between;
`;

export const UserPosition = styled.span`
  font-weight: 600;
  font-size: 1.1rem;
  margin-right: 0.75rem;
  margin-left: 0.57rem;
  min-width: 24px;
  text-align: center;
  margin-top: -0.05rem
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.span<{ isCurrentUser?: boolean }>`
  font-weight: ${({ isCurrentUser }) => isCurrentUser ? '600' : '400'};
  font-size: 1rem;
`;

export const UserEmail = styled.span`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.grayText};
`;