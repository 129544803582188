import { baseApi } from '../baseApi';
import { AchievementResponseType } from 'models/achievement/achievement';
import { endpoints, GetAchievementStateType } from './endpointDefinitions';

const achievementApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAchievements: builder.query<AchievementResponseType[], void>({
      query: () => ({
        url: '/achievement',
        method: 'GET',
      }),
      providesTags: ['Achievement'],
    }),
    getAchievement: builder.query<
      GetAchievementStateType['result'],
      GetAchievementStateType['queryArgs']
    >({
      query: ({ slug }) => ({
        url: endpoints.getAchievementEntity.path(slug),
        method: endpoints.getAchievementEntity.method,
      }),
    }),
  }),
});

export const {
  useGetAchievementsQuery,
  useGetAchievementQuery,
} = achievementApi;
