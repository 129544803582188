import styled, { createGlobalStyle, DefaultTheme } from 'styled-components';

// Themes
export const defaultTheme: DefaultTheme = {
  fontFamily: "'Mark', sans-serif",
  colors: {
    purple: '#931AD4',
    purpleDark: '#8313BF',
    pink: '#FD4D8B',
    pinkDark: '#ED3E7B',
    blue: '#2A6BF4',
    blueDark: '#1C5DE5',
    blueLight: '#C6D7FC',
    teal: '#22E9B3',
    tealDark: '#00DAB3',
    orange: '#FD6923',
    orangeDark: '#F0570E',
    yellow: '#FFF204',
    yellowDark: '#F9E213',
    white: '#FFFFFF',
    backgroundLight: '#EBF2F5',
    backgroundDark: '#C3CED9',
    borderLight: '#D8E1E5',
    borderDark: '#A6B7BF',
    grayText: '#626A73',
    black: '#1A1A1A',
    blackDark: '#000000',
    success: '#0ACF87',
    successDark: '#02b171',
    error: '#FD4D8B',
    caution: '#FDB123',
    information: '#2A6BF4',
    gold: '#D4AF37',
    goldBright: '#f5d551',
    silver: '#C0C0C0',
    bronze: '#9F7A34',
    bronzeBright: '#c77d34',
    bronzeDark: '#8B6914',
    mapIconColor: 'rgb(102, 102, 102)',
    goodGreen: '#029660',
    approvedGreen: '#0ACF87',
    changeIsNeededYellow: '#FDB123',
    focusAreaRed: '#FD4D8B'
  },
} as const;

export type ThemeColor = keyof DefaultTheme['colors'];

// Breakpoints
export const breakpoints = {
  xs: 375,
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
  xxl: 1400,
} as const;

export type Breakpoint = keyof typeof breakpoints;

// Shadows
export const shadows = {
  normal: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
  overlay: '0 4px 8px rgba(0, 0, 0, 0.25)',
};

export const Description = styled.p`
  margin-bottom: 32px;
`;

export const CoverContent = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 96px auto 64px;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 12px;

  button {
    width: 100%;
  }
`;

type ContentWidthProps = {
  isSurface?: boolean;
  noMargins?: boolean;
};

export const ContentWidth = styled.div<ContentWidthProps>`
  position: relative;
  max-width: 680px;
  margin: ${({ noMargins }) => (noMargins ? '0 auto' : '32px auto')};
  background: ${({ isSurface }) =>
    isSurface ? defaultTheme.colors.white : 'none'};
  border-radius: ${({ isSurface }) => (isSurface ? '24px 24px 0 0' : '0')};

  @media (max-width: ${breakpoints.m}px) {
    overflow-x: hidden;
    max-width: none;
    padding: ${({ isSurface }) => (isSurface ? '16px' : '0')};
    margin: 0;
  }
`;

export const TwoColGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media (max-width: ${breakpoints.m}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ColGrid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  gap: 16px;

  @media (max-width: ${breakpoints.m}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Gap = styled.div`
  height: 48px;
`;

export const MediumGap = styled.div`
  height: 32px;
`;

export const SmallGap = styled.div`
  height: 20px;
`;

export const Divider = styled.div`
  height: 1px;
  background: ${defaultTheme.colors.borderLight};
`;

export const Category = styled.p`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 8px;
`;

type MediaProps = {
  breakpoint: Breakpoint;
  isLarger?: boolean;
};

export const MediaDisplay = styled.div<MediaProps>`
  display: none;

  ${({ breakpoint, isLarger = false }) => `
    @media (${isLarger ? 'min' : 'max'}-width: ${
    breakpoints[breakpoint] + (isLarger ? 1 : 0)
  }px) {
      display: block;
    }
  `}
`;

type FlexContainerProps = {
  onClick?: () => void;
  cursor?: 'pointer' | 'default';
  gap?: number;
  flexDirection?: 'row' | 'column';
  flexGrow?: number;
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?:
    | 'stretch'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'start'
    | 'end'
    | 'self-start'
    | 'self-end';
};

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  flex-grow: ${({ flexGrow }) => flexGrow || 0};
  gap: ${({ gap }) => gap || 0}px;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  cursor: ${({ onClick, cursor }) =>
    cursor ? cursor : onClick ? 'pointer' : 'default'};
`;

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    background: ${defaultTheme.colors.white};
    font-family: ${defaultTheme.fontFamily};
    height: 100%;
  }

  body > div {
    height: 100%;
  }

  #confetti {
    height: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
    margin: 16px 0;
  }

  h5 {
    font-size: 16px;
    margin: 0;
  }

  h6 {
    font-size: 14px;
    margin: 0;
    font-weight: 400;
  }

  a {
    color: ${defaultTheme.colors.blue};
    text-decoration: none;
    font-weight: 600;
  }
`;

export default GlobalStyle;
