import { paths, components } from 'generated/api';

// Recursively make all properties of T required
export type DeepRequired<T> = {
  [K in keyof T]: Required<DeepRequired<T[K]>>;
};

export type ExtractResponseType<
  T extends keyof paths,
  Method extends keyof paths[T]
> = paths[T] extends {
  [K in Method]: {
    responses: { 200: { content: { 'application/json': infer R } } };
  };
}
  ? R
  : never;

export type ExtractRequestBody<
  T extends keyof paths,
  Method extends keyof paths[T]
> = paths[T] extends {
  [K in Method]: {
    requestBody?: { content: { 'application/json': infer R } };
  };
}
  ? R
  : undefined;

export type ExtractRequestParams<
  T extends keyof paths,
  Method extends keyof paths[T]
> = paths[T] extends {
  [K in Method]: {
    parameters?: infer R;
  };
}
  ? R
  : undefined;

export type ExtractApiResponseType<
  T extends keyof paths,
  Method extends keyof paths[T]
> = ExtractResponseType<T, Method>;

export type ExtractApiRequestBody<
  T extends keyof paths,
  Method extends keyof paths[T]
> = ExtractRequestBody<T, Method>;

export type ExtractApiRequestParams<
  T extends keyof paths,
  Method extends keyof paths[T]
> = ExtractRequestParams<T, Method>;

export type WellrEndpointPath = keyof paths;

export type WellrEndpointDefinition<
  Path extends WellrEndpointPath,
  Method extends keyof paths[Path]
> = {
  request: {
    params: ExtractApiRequestParams<Path, Method>;
    body: ExtractApiRequestBody<Path, Method>;
  };
  responseBody: DeepRequired<ExtractApiResponseType<Path, Method>>;
};

/** Bad type in API for content */
export type ApiContentDefinition = {
  [key: string]: components['schemas']['JsonNode'] | undefined;
} | null;

export function getEndpointURL(
  url: WellrEndpointPath,
  replace?: Record<string, string>
) {
  let endpoint: string = url;
  if (replace) {
    Object.keys(replace).forEach((key) => {
      endpoint = endpoint.replace(`{${key}}`, replace[key]);
    });
  }
  // Remove /api prefix from endpoint:
  endpoint = endpoint.replace('/api', '');
  return endpoint;
}

export type ObjectWithoutUndefined<T> = Exclude<
  { [K in keyof T]-?: Exclude<T[K], undefined> },
  undefined
>;

