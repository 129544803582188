import styled from 'styled-components';
import { breakpoints } from 'styles';

export const BadgeDetailsContainer = styled.div`
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: pulseGlow 2s infinite;

  @keyframes pulseGlow {
    0% {
      box-shadow: 0px 0px 15px rgba(0, 123, 255, 0.2),
        0px 0px 30px rgba(0, 123, 255, 0.3);
    }
    50% {
      box-shadow: 0px 0px 20px rgba(0, 123, 255, 0.4),
        0px 0px 40px rgba(0, 123, 255, 0.6);
    }
    100% {
      box-shadow: 0px 0px 15px rgba(0, 123, 255, 0.2),
        0px 0px 30px rgba(0, 123, 255, 0.3);
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    max-height: 960px;

    .badge-image {
      position: relative;
      margin-bottom: 20px;
      img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .badge-details {
      color: ${({ theme }) => theme.colors.grayText};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 500px;

      h2 {
        margin-bottom: 0px;
        font-size: 1.5rem;
        color: #222;
        font-weight: bold;
      }

        h6 {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 245px;
        font-weight: 500;
        background-color: #e0e0e0;
        border-radius: 10px;
        padding: 0.1rem 1.2rem
      }

      p {
        font-size: 1rem;
        color: #555;
        padding: 10px 15px;
        line-height: 1.5;
        text-align: center;
        margin-top: 0px;
      }
    }

    .continue-btn {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 15px;
      cursor: pointer;
      font-size: 1rem;
      transition: background-color 0.3s;
      width: 60%;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  @media (max-width: ${breakpoints.s}px) {
    height: 100vh;
    width: 100%;

    .modal-content {
      flex: 2;
      display: flex;
      flex-direction: column;
      padding: 16px;
      justify-content: space-around;
      margin-bottom: 4.375em;

      .badge-image {
        position: relative;

        img {
          width: 200px;
          height: 200px;
        }

        .new-logo {
          position: absolute;
          top: 10px;
          right: 21px;
          width: 79px;
          height: 36px;
        }
      }

      .badge-details {
        max-height: 960px;
        h2 {
          font-size: 1.5rem;
        }

        h6 {
          font-size: 0.8rem;
        }

        p {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const IconContainer = styled.div`
  margin-top: 0.5em;
`;

export const LabelContainer = styled.div`
  position: absolute;
  top: 10px;
  right: calc(50% - 105px); // Based on image width (200px) + some offset
  width: 73px;
  height: 36px;
  background: #FBD323;
  border-radius: 11px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

export const DiagonalOverlay = styled.div`
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  background: rgba(255, 238, 102, 0.6);
  transform: translateX(-50%) skewX(-40deg);
  transform-origin: center;
`;

export const LabelText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  font-weight: bolder;
  color: #806C13;
  text-transform: uppercase;
`;

export const ButtonContainer = styled.div`
margin-top 1.5em;
width: 100%;
display: flex;
justify-content: center;

> button {
  max-width: 340px;
  }

  @media (max-width: ${breakpoints.s}px) {
    position: absolute;
    right: 0;
    bottom: 20px;
  }
`;

