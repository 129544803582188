import Lottie from 'lottie-react';
import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;

  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const Card = styled.div<{ inverted: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 12px;
  font-size: 16px;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: -20px;
  background: ${({ theme, inverted }) =>
    inverted ? theme.colors.white : theme.colors.backgroundLight};

  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
    margin 0 16px;
    padding: 10px;
    border-radius: 12px;
  }
  @media (max-width: ${breakpoints.s}px) {
    height: auto;
    padding: 2px;
    margin-top: 0px;
    margin-bottom: -15px;
  }
`;

export const CardRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 16px;

  @media (max-width: ${breakpoints.m}px) {
    gap: 12px;
    padding: 0 10px;

    & > * {
      flex: 1;
    }

    & > :first-child,
    & > :nth-child(2) {
      margin: 0 5px;
    }
  }

  @media (max-width: ${breakpoints.s}px) {
    gap:8px;
    & > * {
      flex: 1;
    }

    & > :first-child,
    & > :nth-child(2) {
      margin: 0px;
    }
  }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 16px;
  padding: 8px;
  height: 26px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.blueLight};

  @media (max-width: ${breakpoints.m}px) {
    display: none;
  }
`;

export const ProgressContainer = styled.div`
  position: relative;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  > * {
  margin: 4px !important;
  }

  @media (max-width: ${breakpoints.s}px) {
    > * {
    margin: 0 !important;
    margin-right: 4px !important;
    }
  }
`;

export const StyledLottie = styled(Lottie)`
  position: absolute;
  top: -20px;
  right: 4px;
  width: 100%;
  height: 150%;

  pointer-events: none;
  z-index: 10;

 @media (max-width: ${breakpoints.s}px) {
    right: 8px;
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 97%;
  height: auto;
  padding: 0px;
  margin-bottom: -10px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};

  @media (max-width: ${breakpoints.m}px) {
    padding: 4px 0;
    margin: 0;
    height: auto;
  }

  @media (max-width: ${breakpoints.s}px) {
    padding: 0;
    height: auto;
    > *{
    padding: 8px;
    }
  }

`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;

  > * {
    margin: 0;
    padding: 0;
  }

  > *:first-child {
    margin-right: 0;
  }

  @media (max-width: ${breakpoints.m}px) {
    justify-content: center;
    padding: 0;
    margin-top: 0;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));

  @media (max-width: ${breakpoints.m}px) {
    background: rgba(0, 0, 0, 0.5);
  }
  `;

export const CardTitle = styled.p<{ inverted?: boolean }>`
  color: ${({ theme, inverted }) =>
    inverted ? theme.colors.borderLight : theme.colors.grayText};
  font-size: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 15px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  margin-top: 5px;
  white-space: nowrap;

  @media (max-width: ${breakpoints.m}px) {
    gap: 10px;
    padding-left: 0px;
  }
`;

interface CheckboxContainerProps {
  disabled?: boolean;
}

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 16px;
  padding-right: 35px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  @media (max-width: ${breakpoints.m}px) {
    top: 10px;
    right: 10px;
  }
`;
export const ImageContainer = styled.div`
  width: 100%;
  margin-top: -10px;
  margin-left: -12px;

  @media (max-width: ${breakpoints.m}px) {
    height: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    height: auto;
  }
`;

export const ImageView = styled.img`
  width: 110%;
  height: 205px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-left: 6px;
  margin-top: 4px;
  justify-content: center;

  @media (max-width: ${breakpoints.m}px) {
    height: 220px;
    width: 100%;
  }

  @media (max-width: ${breakpoints.s}px) {
    margin-top: 8px;
    min-width: 100px;

    max-height: 125px;
    margin-left: 0px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
  margin-top: -10px;

  @media (max-width: ${breakpoints.m}px) {
    padding: 10px;
  }

  @media (max-width: ${breakpoints.s}px) {
    padding: 1px;
    min-width: 200px;
  }
`;

export const CardContent = styled.div<{ center?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
  margin: 0 8px;
  max-height: 270px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  @media (max-width: ${breakpoints.m}px) {
    margin: 0;
    max-height: 180px;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    max-height: 140px;
    padding-right: 30px;
  }
`;

export const CardText = styled.div`
  flex: 1;
  margin: 0;
  margin-right: 16px;
  padding-bottom: 20px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  h3 {
    overflow: auto;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  @media (max-width: ${breakpoints.m}px) {
    h3 {
      font-size: 14px;
      padding-right: 16px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    h3 {
      font-size: 14px;
      margin-right: 0px;
      padding-right: 0px;
    }
    margin-right: 0px;
    padding-bottom: 0px;
  }
`;

export const CardTextHeader = styled.div`
  display: flex;
  flex-direction: start;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  @media (max-width: ${breakpoints.m}px) {
    margin: 0;
    padding: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16px;
  height: 100%;

  h3 {
    margin: 0;
  }

  @media (max-width: ${breakpoints.m}px) {
    margin: 0 8px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Description = styled.p`
  padding: 1px;
  max-height: 130px;
  white-space: normal;
  margin: 0;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 14px;
    width: 100%;
    }

    @media (max-width: ${breakpoints.s}px) {
      font-size: 12px;
      height: 100px;
      max-height: 83px;
      width: 100%;
  }
`;
