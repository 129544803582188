import {
  ContentBody,
  ContentImage,
  ContentText,
  Reference,
} from 'models/content/content';
import { QuizType } from 'models/quiz/quiz';

export type ContestType = 'Competition' | 'Challenge';
export type Difficulty = 'Easy' | 'Medium' | 'Hard';

export type ContestFinish = 'Goal' | 'Time';

export type ContestEmailType =
  | 'Winner'
  | 'Looser'
  | 'Invite'
  | 'Welcome'
  | 'Checkpoint';

export type ContestGoalType = 'A_b' | 'Activity' | 'FreeText' | 'Derby';

export type ContestGoalUnit = 'Steps' | 'Minutes' | 'Meters' | 'Bool';

export type ContestGoalUnits = 'Steps' | 'Minute' | 'Meters' | 'Bool';

export type ContestReminder = 'None' | 'Daily' | 'Weekly' | 'Monthly';

export type ContestGenerateTeams =
  | 'Random'
  | 'HeadOffice'
  | 'Office'
  | 'Department';

export type ContestItem = {
  id: string;
  type: ContestType;
  title: ContentText | null;
  image: ContentImage | null;
  wellrPoints: number;
  start: string;
  stop: string;
  totalAmount: number;
  accepted: boolean;
  completed: boolean;
};

export type ContestTag = {
  slug: string;
  title: string;
};

export type ContestAvailable = {
  slug: string;
  type: ContestType;
  difficulty: Difficulty;
  title: ContentText | null;
  image: ContentImage | null;
  wellrPoints: number;
  description: ContentBody | null;
  tags: ContestTag[];
};

export type ContestEmail = {
  subject: ContentText;
  title: ContentText;
  body: ContentText;
  image: string[];
  type: ContestEmailType;
  html: boolean;
};

export type ContestSubGoal = {
  id: string;
  title: ContentText;
  description: ContentText;
  lat: number;
  lng: number;
  amount: number;
  markOnMap: boolean;
};

export type HealthChoice = {
  id: string;
  title: ContentText;
  bonus: number;
};

export type ContestQuiz = {
  id: string;
  quizType: string;
  slug: string;
  title: ContentText;
  summary: ContentText;
  contestAwardedStepsOnComplete: number;
}

export type QuizComplete = {
  from: string;
  goalId: string;
  contestId?: string;
  quizId: string;
  quizType: QuizType;
}

export type ContestGoal = {
  id: string;
  start: string;
  startLat: number | null;
  startLng: number | null;
  stop: string;
  stopLat: number | null;
  stopLng: number | null;
  subGoals: ContestSubGoal[];
  contestQuizzes: ContestQuiz[];
  activity: string[] | null;
  healthChoices: HealthChoice[];
  maxHealthChoicesPerDay: number;
  unit: ContestGoalUnit;
  amount: number;
  reminder: ContestReminder;
  allowedActivities: string[];
  allowAllActivities: boolean;
  allowNoActivities?: boolean | null;
  description: ContentText | null;
  type: ContestGoalType;
};

export type CompletedGoal = {
  id: string;
  date: string;
};

export type ContestUserOut = {
  id: string;
  email: string | null;
  alias: string;
  total: number;
  progress: number;
  completedGoals: CompletedGoal[];
  completedCheckpoints: CompletedGoal[];
  profilePictureAssetId: string | null;
};

export type ContestUser = {
  membershipId: string;
  alias: string;
  total: {
    date: string;
    total: number;
  };
  completedGoals: CompletedGoal[];
  completedCheckpoints: CompletedGoal[];
};

export type ContestTeamUser = {
  id: string;
  alias: string;
  email: string | null;
  position: number;
  profilePictureAssetId: string | null;
  image: string | null;
  total: number;
};

export type ContestTeamPendingUser = {
  id: string;
  alias: string | null;
  email: string | null;
};

export type ContestTeam = {
  id: string;
  name: string;
  captain: string;
  total: number;
  position: number;
  completed: string;
  pendingInvites: ContestTeamPendingUser[];
  users?: ContestTeamUser[];
};

export type ContestMeta = {
  individualResults: boolean;
  individualTopList: boolean;
  topListLength: number;
  maxUsersPerTeam: number;
  minUsersPerTeam: number;
};

export type ContestTeamInvite = {
  alias: string;
  captainEmail: string;
  captainName: string;
  inviteId: string;
  teamName: string;
};

export type CompletedHealthChoices = {
  [goalId: string]: string[];
};

export type CompletedContestQuizzes = {
  [quizId: string]: string[];
};

export type PendingActivity = {
  registered: string;
  status: 'Valid' | 'Invalid' | 'IncorrectType' | 'TooOld';
  activitySlug: string | null;
  steps: number;
  numberOfUnits: number;
};

export type UserInvite = {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
};

export type ContestMe = {
  id: string;
  alias: string;
  profilePictureAssetId: string | null;
  total: number;
  completedHealthChoices: CompletedHealthChoices;
  completedContestQuizzes: CompletedContestQuizzes;
  completedGoals: CompletedGoal[];
  completedCheckpoints: CompletedGoal[];
  team: ContestTeam | null;
  teamInvites: ContestTeamInvite[];
  pendingActivities: PendingActivity[];
  pendingActivitiesStepsToAdd: number;
  userInvites: null | UserInvite[];
};

export type Contest = {
  id: string;
  type: ContestType;
  title: ContentText | null;
  description: ContentBody | null;
  companyId: string;
  canCreateTeam: boolean;
  userId: string;
  generateTeams: ContestGenerateTeams;
  individualResults: boolean;
  individualTopList: boolean;
  maxUsersPerTeam: number;
  minUsersPerTeam: number;
  finish: ContestFinish;
  reference: Reference[] | null;
  compensate: boolean;
  maxAmountPerDay: number;
  totalAmount: number;
  accepted: boolean;
  image: ContentImage | null;
  wellrPoints: number;
  goals: ContestGoal[];
  checkpoints: ContestSubGoal[];
  teams: ContestTeam[];
  users: ContestUserOut[] | null;
  toplist: ContestTeamUser[] | null;
  meta: ContestMeta;
  emails: ContestEmail[];
  doInvite: boolean;
  me: ContestMe;
  start: string;
  stop: string;
  startedBy: {
    companyId?: string;
    userId?: string;
  };
  completed: string | null;
  canUploadTeams: boolean;
  darkMode: boolean;
  unit?: ContestGoalUnits;
  slug?: string;
  specialContest: SpecialContestType
};

export type ContestPreview = {
  id: string;
  type: ContestType;
  title: ContentText | null;
  image: ContentImage | null;
  description: ContentBody | null;
  wellrPoints: number;
  compensateTeams: boolean;
  individualResult: boolean;
  individualTopList: boolean;
  start: string;
  stop: string;
  invitedBy: string;
  finish: ContestFinish;
};

export type ContestQuery = {
  language?: string;
  contestId?: string;
  slug?: string;
};

export type ContestAnswerBody = {
  contestId: string;
  accept: boolean;
  alias?: string;
  displayEmail?: boolean;
};

export type ContestTeamInviteAnswer = {
  contestId: string;
  inviteId: string;
  accept: boolean;
};

export type ContestTeamInviteBody = {
  contestId: string;
  teamId: string;
  membershipIds: string[];
};

export type ContestTeamInviteResponse = {
  contestId: string;
  teamId: string;
  invitedMembershipIds: string[];
};

export type ContestTeamSearchMembersBody = {
  contestId: string;
  body: {
    searchTerm: string;
  };
};

export type ContestTeamInvitableUser = {
  id: string;
  membershipId: string;
  email: string;
  alias: string;
};

export type ContestTeamSearchMembersResponse = {
  invitableUsers: ContestTeamInvitableUser[];
};

export type ContestTeamDeleteBody = {
  contestId: string;
};

export type ContestSlugTitle = {
  slug: string;
  title: string;
};

export type ContestTeamCreateBody = {
  contestId: string;
  teamName: string;
};

export type ContestEditAliasBody = {
  contestId: string;
  alias: string;
};

export type ContestTeamCreateReponse = {
  contestId: string;
  teamId: string;
  teamName: string;
  created: string;
};

export type ContestAnswerResponse = {
  contestId: string;
  accepted: string;
  declined: string;
};

export type SubGoalData = {
  id: string;
  startLat: number | null;
  startLng: number | null;
  stopLat: number | null;
  stopLng: number | null;
  amount: number;
};

export type GoalData = {
  id: string;
  startLat: number;
  startLng: number;
  stopLat: number;
  stopLng: number;
  subGoals: SubGoalData[];
};

export type ContestContext = {
  contest: Contest;
  isFetching: boolean;
  refetch: () => void;
  specialCompetiton: SpecialContestType;
};

export enum SpecialContestType{
  Pink = 'Pink',
  Christmas = 'Christmas',
}

export type ContestHealthChoiceBody = {
  contestId: string;
  goalId: string;
  healthChoiceId: string;
};
export type ContestQuizBody = {
  contestId: string;
  goalId: string;
  contestQuizId: string;
};

export type ContestHealthGoalBody = {
  contestId: string;
  goalId: string;
};

export type ContestStartBody = {
  slug: string;
  userId: string;
  start: string;
  stop?: string;
  users?: Partial<ContestUser>[];
};
