import ConfirmActionPopup from 'components/popups/ConfirmActionPopup';
import Button from 'components/UI/Button';
import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import TextField from 'components/UI/TextField';
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContentWidth } from 'styles';
import { ButtonContainer } from './styles';
import { useRemoveMembershipsFromCompanyMutation } from 'store';
import toast from 'react-hot-toast';

type Props = {
  companyId: string;
}

const RemoveCompanyMembershipForm: FC<Props> = ({ companyId }) => {

  // Hooks
  const intl = useIntl();
  const [removeMemberships, { isLoading, isError, isSuccess }] = useRemoveMembershipsFromCompanyMutation();

  // States
  const [showConfirm, setShowConfirm] = useState(false);


  useEffect(() => {
    if (isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'updateCompanySuccess',
        })
      );
    } else if (isError) {
      toast.error(
        intl.formatMessage({
          id: 'updateCompanyError',
        })
      );
    }
      setShowConfirm(false);
  }, [intl, isError, isSuccess]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<{ file: FileList }>();

  const onSubmit = handleSubmit(({ file }) => {
    removeMemberships({ file: file[0], companyId });
  });


  return (
    <ContentWidth>
      <br />
      <form onSubmit={onSubmit}>
        <TextField
          label={intl.formatMessage({
            id: 'inputCompanyRemoveMembershipsFileInputLabel',
            defaultMessage: 'Remove memberships in company from excel file. Only first column with rows of email addresses',
          })}
          type="file"
          error={errors.file}
          register={register('file', { required: true })}
        />
        <ButtonContainer>
          <Button
            onClick={() => {
              setShowConfirm(true);
            }}
            background="error"
            disabled={isLoading}
          >
            <FormattedMessage
              id="removeButton"
              defaultMessage="remove"
              description="Remove button text"
            />
          </Button>
        </ButtonContainer>
        {isLoading && <Loader color="blue" padding />}
      </form>
      <ConfirmActionPopup
        isOpen={showConfirm}
        onConfirm={onSubmit}
        onAbort={() => setShowConfirm(false)}
      >
        <>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="deleteMembershipConfirmHeading"
            />
          </Heading>
          <FormattedMessage
            id="deleteMembershipConfirmBody"
          />
          <br />
        </>
      </ConfirmActionPopup>
    </ContentWidth>
  )
}

export default RemoveCompanyMembershipForm