import { baseApi } from '../baseApi';
import { UserAchievementResponseType } from 'models/userachievement/userachievement';

const updateAchievementApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateUserAchievement: builder.mutation<void, UserAchievementResponseType>({
      query: (userAchievement) => ({
        url: '/user-achievement',
        method: 'PUT',
        body: userAchievement, 
      }),
      invalidatesTags: ['UpdateUserAchievement'], 
    }),
  }),
});

export const { useUpdateUserAchievementMutation } = updateAchievementApi;
