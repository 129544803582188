import { backendApi } from 'config';
import { components } from 'generated/api';
import { CategoryIcon, ContentImage } from 'models';
import defaultprofile from '../../assets/RankIcon/profile.svg';

export function getAssetUrl(ref: string, parameter: string | null = null) {
  const base = `${backendApi}/content/asset/${ref}`;
  return parameter ? `${base}?parameter=${parameter}` : base;
}

function buildParameters(height: number | null, format: string | null) {
  let out = '';
  if (height !== null && format !== null) {
    out = `fm=${format}&h=${height}`;
  } else if (height !== null) {
    out = `h=${height}`;
  } else if (format !== null) {
    out = `fm=${format}`;
  }
  return encodeURIComponent(out);
}

export function getImage(
  image?: ContentImage | null | any,
  height: number | null = 800,
  format: string | null = 'webp'
): HTMLImageElement | null {
  if (!image?.asset?._ref) {
    return null;
  }
  const img = new Image();
  img.src = getAssetUrl(image.asset._ref, buildParameters(height, format));
  img.loading = 'lazy';
  return img;
}

export function getCategoryIcon(
  icon: CategoryIcon | components['schemas']['HealthCategoryDto']['icon'],
  inverted = false
): HTMLImageElement | null {
  if (inverted) {
    return icon?.inverted ? getImage(icon.inverted, null, null) : null;
  }
  return icon?.default ? getImage(icon.default, null, null) : null;
}



export async function getProfileImage(
  assetId: string|null,
  accessToken?: string
): Promise<string | null> {
  if (!assetId) {
    console.error('Asset ID is required to fetch the profile image.');
    return defaultprofile;
  }

  const baseUrl = `${backendApi}/assets/${assetId}`;
  const headers: Record<string, string> = {
    'Content-Type': 'application/json'
  };

  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  try {
    const response = await fetch(baseUrl, {
      method: 'GET',
      headers
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }

    const blob = await response.blob();
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  } catch (error) {
   
    return defaultprofile; 
  }
}
