import styled from 'styled-components';
import { breakpoints } from 'styles';

export const ProfileWrapper = styled.section<{
  columnLayout?: boolean;
  noBackground?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  rank?: string;
}>`
  background-color: ${({ noBackground }) =>
    noBackground ? 'transparent' : '#f9f9f9'};
  padding: ${({ columnLayout }) => (columnLayout ? '10px' : '20px')};
  display: flex;
  justify-content: ${({ columnLayout }) =>
    columnLayout ? 'flex-start' : 'space-between'};
  align-items: center;
  text-align: center;
  max-width: 94%;
  flex-direction: ${({ columnLayout }) => (columnLayout ? 'column' : 'row')};
  gap: 0px;

    // Conditionally remove box shadow for ranks 1, 2, and 3
  box-shadow: ${({ rank }) =>
    rank === '1' || rank === '2' || rank === '3'
      ? 'none'
      : '0px 0px 15px rgba(0, 0, 0, 0.1)'};

  border-top-left-radius: ${({ isFirst }) => (isFirst ? '10px' : '0')};
  border-top-right-radius: ${({ isFirst }) => (isFirst ? '10px' : '0')};
  border-bottom-left-radius: ${({ isLast }) => (isLast ? '10px' : '0')};
  border-bottom-right-radius: ${({ isLast }) => (isLast ? '10px' : '0')};
  border-radius: 5px;
  margin-bottom: 2px;

  & > * {
    margin: ${({ columnLayout }) => (columnLayout ? '0' : '0')};
  }

  @media (max-width: ${breakpoints.s}) {
    padding: ${({ columnLayout }) => (columnLayout ? '10px' : '15px')};
  }

  @media (max-width: ${breakpoints.xs}px) {
    box-shadow: none;
    padding: 2px;
    justify-content: space-evenly;
    max-width: 100%;
    gap: 8px;
  }
`;

export const RankText = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #333;

  flex: 1;
`;

export const ProfileImage = styled.img<{
  rankSize?: 'first' | 'second' | 'third' | string;
}>`
  width: ${({ rankSize }) =>
    rankSize === 'first'
      ? '190px'
      : rankSize === 'second'
      ? '130px'
      : rankSize === 'third'
      ? '130px'
      : '80px'};
  height: ${({ rankSize }) =>
    rankSize === 'first'
      ? '190px'
      : rankSize === 'second'
      ? '130px'
      : rankSize === 'third'
      ? '130px'
      : '80px'};
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0px;



  @media (max-width: ${breakpoints.s}px) {
    width: ${({ rankSize }) =>
      rankSize === 'first'
        ? '100px'
        : rankSize === 'second'
        ? '90px'
        : rankSize === 'third'
        ? '90px'
        : '40px'};
    height: ${({ rankSize }) =>
      rankSize === 'first'
        ? '100px'
        : rankSize === 'second'
        ? '90px'
        : rankSize === 'third'
        ? '90px'
        : '40px'};
  }

  @media (max-width: ${breakpoints.xs}px) {
    width: ${({ rankSize }) =>
      rankSize === 'first'
        ? '100px'
        : rankSize === 'second'
        ? '80px'
        : rankSize === 'third'
        ? '70px'
        : '50px'};
    height: ${({ rankSize }) =>
      rankSize === 'first'
        ? '100px'
        : rankSize === 'second'
        ? '80px'
        : rankSize === 'third'
        ? '70px'
        : '50px'};
  }
`;

export const NameText = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: #555;
  flex: 2;
  white-space: nowrap;
  text-align: left;
  padding-left:20px;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${breakpoints.xs}px) {
    font-size: 10px;
    padding-left:5px;
    max-width: 130px;
  }
`;


export const CompanyText = styled.p`
  font-size: 14px;
  color: #777;

  flex: 1;

  @media (max-width: ${breakpoints.xs}px) {
    font-size: 10px;
    font-weight: bold;
  }
`;

export const WellrpointWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  flex: 1;
  @media (max-width: ${breakpoints.xs}px) {
    gap: 2px;
  }
`;

export const WellrpointLogo = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  vertical-align: middle;
  @media (max-width: ${breakpoints.xs}px) {
    width: 15px;
    height: 15px;
  }
`;

export const WellrpointText = styled.span`
  font-size: 14px;
  color: #333;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;

  @media (max-width: ${breakpoints.xs}px) {
    font-size: 12px;
  }
`;

export const WellrstepsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  flex: 1;
`;

export const WellrstepsLogo = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  vertical-align: middle;
`;

export const WellrstepsText = styled.span`
  font-size: 14px;
  color: #333;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;

  @media (max-width: ${breakpoints.xs}px) {
    font-size: 12px;
  }
`;
export const RankSvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 60px;
  margin-bottom: -24px;

  @media (max-width: ${breakpoints.xs}px) {
    width: 60px;
    height: 40px;
    margin-bottom: -29px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 60px;
    height: 40px;
    margin-bottom: -29px;
  }
`;

export const RankIcon = styled.img`
  width: 80px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 26px;

  @media (max-width: ${breakpoints.xs}px) {
  width: 60px;
  height: 40px;
  object-fit: contain;
  margin-bottom: 27px;
    }
  }
     @media (max-width: ${breakpoints.s}px) {
  width: 60px;
  height: 40px;
  object-fit: contain;
  margin-bottom: 46px;
    }
  }
`;
