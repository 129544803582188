import { FC, useEffect, useMemo, useState } from 'react';
import { ThemeColor } from 'styles';
import { Bar, Container, GoalFlag, HorseIcon, Marker, MarkerContainer, ProgressBarContainer, ProgressContainer } from './styles';
import { CompletedGoal, ContestGoal, ContestGoalUnits } from 'models';
import { isWithinInterval } from 'date-fns';
import horse from 'assets/vectors/horse.svg';
import markerGoal from 'assets/vectors/achievement-badge.svg';
import flagGoal from 'assets/vectors/derby-flag-goal.svg';

type Props = {
  progress: number;
  color?: ThemeColor | string;
  goals: ContestGoal[];
  completedCheckpoints?: CompletedGoal[];
  completedGoals?: CompletedGoal[];
  unit?: ContestGoalUnits;
};

const DerbyProgressBar: FC<Props> = ({
  progress,
  color = 'blue',
  goals,
  completedGoals,
  completedCheckpoints,
  unit
}) => {
  const [totalGoalsAmount, setTotalGoalsAmount] = useState<number>(0);
  const [currentProgress, setCurrentProgress] = useState<number>(0);

  useEffect(() => {
    if (goals) {
      setTotalGoalsAmount(goals.map((goal) => goal.amount).reduce((a, b) => a + b, 0));
    }
  }, [goals]);

  useEffect(() => {
    if (completedGoals && goals && unit === 'Minute') {
      setCurrentProgress((progress / totalGoalsAmount) * 100);
    }
  }, [completedGoals, goals, progress, totalGoalsAmount, unit]);

  const markers = useMemo(() => {
    if (!goals || goals[0].type === 'A_b') return null;

    return goals.flatMap((goal, index) => {
      if (!goal.subGoals.length && index !== 0) {
        return (
          <Marker
            src={markerGoal}
            key={goal.id}
            style={{ left: `${((goal.amount * index / totalGoalsAmount) * 100)}%` }}
            isCurrent={isWithinInterval(new Date(), {
              start: new Date(goal.start),
              end: new Date(goal.stop),
            })}
          />
        );
      }

      return goal.subGoals.map((subGoal) => (
        <Marker
          src={markerGoal}
          key={subGoal.id}
          style={{ left: `${((subGoal.amount / totalGoalsAmount) * 100)}%`, visibility: subGoal.markOnMap ? 'visible' : 'hidden' }}
          isCurrent={isWithinInterval(new Date(), {
            start: new Date(goal.start),
            end: new Date(goal.stop),
          })}
        />
      ));
    });
  }, [goals, totalGoalsAmount]);

  const cappedProgress = Math.min(currentProgress ? currentProgress : progress, 100);

  return (
    <Container>
      <HorseIcon
        src={horse}
        initial={{ left: 0 }}
        animate={{ left: `${cappedProgress}%` }}
        transition={{ duration: 1 }}
      />
      <ProgressContainer>
        <MarkerContainer>
          {markers}
        </MarkerContainer>
        <ProgressBarContainer>
          <Bar
            initial={{ width: 0 }}
            animate={{ width: `${cappedProgress}%` }}
            transition={{ duration: 1 }}
            color={color}
          />
        </ProgressBarContainer>
        <GoalFlag src={flagGoal} alt="points badge" />
      </ProgressContainer>
    </Container>
  );
};

export default DerbyProgressBar;